import React, { useEffect } from "react"
import { SketchPicker } from 'react-color';
import { fontFamilyList, fontFamilyListLat } from "../../../models/default.font.list";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../reducers/application.state";
import { textActions } from "../../../reducers/text.slice";
import { controlActions } from "../../../reducers/control.slice";
import { fabric } from "fabric";
import { canvasActrions } from "../../../reducers/canvas.slice";
import { IText } from "fabric/fabric-impl";
import { Tabs } from "antd";

export const TextEditView: React.FC = () => {
    const dispatch = useDispatch();

    const canvasActiveObject = useSelector<IApplicationState, fabric.IText>(state => state.canvas.activeCanvasObject)


     
    const canvasFrame = useSelector<IApplicationState, fabric.Canvas>(state => state.canvas.canvas)
    const selectedOrderItemId = useSelector<IApplicationState, any>(state => state.control.orderItemId)

    const textValue = useSelector<IApplicationState, string>(state => state.text.textValue)
    const textColor = useSelector<IApplicationState, string>(state => state.text.textColor)
    const fontFamily = useSelector<IApplicationState, string>(state => state.text.fontFamily)
    const fontSize = useSelector<IApplicationState, number>(state => state.text.fontSize)
    const fontWeight = useSelector<IApplicationState, string>(state => state.text.fontWeight)

    const displayColorPicker = useSelector<IApplicationState, boolean>(state => state.control.isOpenColorPicker)

    const handleClick = () => {
        dispatch(controlActions.openColorPicker())
    };

    const handleClose = () => {
        dispatch(controlActions.closeColorPicker())
    };

    const handleChange = (color) => {
        dispatch(textActions.setTextColor(color.hex));
    };

    const changeTextValue = (e) => {
        dispatch(textActions.setTextValue(e.target.value))
    }

    useEffect(() => {
        if (canvasActiveObject !== null) {
             
            dispatch(textActions.setTextValue(canvasActiveObject.text))
            dispatch(textActions.setTextColor(canvasActiveObject.fill))
            dispatch(textActions.setFontFamily(canvasActiveObject.fontFamily))
        }

    }, [canvasActiveObject])

    const editTextItem = () => {
        canvasActiveObject.set({
            'text': textValue,
            'fill': textColor,
            'fontFamily': fontFamily
        });
        canvasFrame.renderAll();

        //let textCreator = new fabric.IText(textValue, {
        //    fontFamily: fontFamily,
        //    fontSize: fontSize,
        //    fontWeight: fontWeight,
        //    fill: textColor,
        //    transparentCorners: false
        //});

        //canvasFrame.setActiveObject(textCreator).add(textCreator);
        //canvasFrame.centerObject(textCreator);

        //dispatch(canvasActrions.renderAll())
        //canvasFrame.renderCanvas.bind(canvasFrame);


        //dispatch(textActions.clearTextFields())
        //dispatch(controlActions.closeModal())
    }


    return (
        <div className="text__VIEW_COMPONENT">
            <div className="textView__FORM_CONTAINER">
                <div className="controlStyleElement text_container">
                    <div className="label_control">
                        Текст
                        </div>
                    <input
                        className="input__DEFAULT"
                        onChange={changeTextValue}
                        value={textValue}
                        type="text"
                    />
                </div>
                <div className="namelyCase_colorPicker">
                    <SketchPicker color={textColor} onChange={handleChange} />
                </div>

                <div className="controlStyleElement color_text">
                    <div className="fontStyleWrapper">
                        <div className="fontStyle">
                            <div className="color_box"
                                style={{ backgroundColor: `${textColor}` }}
                                onClick={handleClick}
                            >
                                <div className="color_code">
                                    <div className="label_control">Цвет</div>
                                    {textColor}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="controlStyleElement text_container">
                    <div className="addText_btn" onClick={editTextItem}>Изменить</div>
                </div>

                <div className="input__CONTROL_TEXT_FORMATED">
                    <div className="backgroundColor__CON" onClick={handleClick}>
                        <div className="con__COLOR" style={{ backgroundColor: `${textColor}` }} />
                    </div>

                </div>
            </div>

            <Tabs centered className="text_VIEW_PREW" defaultActiveKey="1" size={'small'} onChange={() => { }}>
                <Tabs.TabPane tab="Латиница" key="1">
                    <div className="fontFamilyList__CONTAINER">
                        {fontFamilyListLat.map((fontFamilyItem: any, index: number) =>
                            <div style={{ fontFamily: `${fontFamilyItem.FontName}` }} className={`list_item ${fontFamily === fontFamilyItem.FontName ? 'isSelected' : 'notSelected'}`} key={index} onClick={() => dispatch(textActions.setFontFamily(fontFamilyItem.FontName))}>
                                <div className="item">
                                    <div className="font">Aa</div>
                                    <div className="name_font">{fontFamilyItem.FontTitle}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Кириллица" key="2">
                    <div className="fontFamilyList__CONTAINER">
                        {fontFamilyList.map((fontFamilyItem: any, index: number) =>
                            <div style={{ fontFamily: `${fontFamilyItem.FontName}` }} className={`list_item ${fontFamily === fontFamilyItem.FontName ? 'isSelected' : 'notSelected'}`} key={index} onClick={() => dispatch(textActions.setFontFamily(fontFamilyItem.FontName))}>
                                <div className="item">
                                    <div className="font">Aa</div>
                                    <div className="name_font">{fontFamilyItem.FontTitle}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </Tabs.TabPane>
            </Tabs>

            <div className="text_VIEW_INFO">
                <ul className="useCanvasListTest">
                    {/*this.state.textCanvasList && this.state.textCanvasList.map((textItem: any, index: number) =>
                        <li key={index} style={{ color: `${textItem.fill}`, fontFamily: `${textItem.fontFamily}` }}>
                            <div className="removeItem" onClick={(event: any) => this.selectedTextItemAndRemove(textItem)} >
                                <IconMenuClose />
                            </div>

                            {textItem.text}
                        </li>

                    )*/}

                </ul>
            </div>

            {
                displayColorPicker &&
                <>
                    <div className="displayColorPicker__CLOSE" onClick={handleClose} />
                    <div className="displayColorPicker__CONTAINER">
                        <SketchPicker color={textColor} onChange={handleChange} />
                    </div>
                </>
            }

        </div>
    )
}