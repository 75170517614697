import { ofType } from "redux-observable";
import { orderActions } from "../reducers/order.slice";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { switchMap, mergeMap, catchError } from "rxjs/operators";
import { notification } from "antd";
import { API } from "../constans/api.constants";
import { IServiceApplicationState } from "../reducers/application.state";
import { controlActions } from "../reducers/control.slice";
import { canvasActrions } from "../reducers/canvas.slice";
import { fabric } from "fabric";
import { OrderItem } from "../models/order.item";
import { push } from "react-router-redux";
import { dispatch } from "rxjs/internal/observable/range";
import { productActrions } from "../reducers/product.slice";
import * as routes from '../constans/routes.constants';
import { List } from "linq-typescript";


export const CreateOrder = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(orderActions.apiCreateOrder.type),
        switchMap((action: any) => {

            return ajax
                .post(`${API.OrderIdentityEndPoints.NEW__ORDER}`,
                    {

                        "OrderItems": [
                            {
                                "ProductId": action.payload.Id,
                                "OrderItemProductOptions": [
                                    {
                                        "ProductOption": {
                                            "Id": action.payload.Colors.length > 0 && action.payload.Colors[0].Id
                                        }
                                    }
                                ],
                                "Quantity": 1,

                            }
                        ]
                    }
                    , { 'Content-Type': 'application/json' })
                .pipe(
                    mergeMap((response: any) => {
                        console.log('CreateOrder')
                        return of(
                            //orderActions.setOrder(response.response.Body),
                            //orderActions.setOrderItem(response.response.Body.OrderItems[0]),
                            controlActions.setStorageOrderId(response.response.Body.Id),
                            controlActions.setStorageOrderItemId(response.response.Body.OrderItems[0].Id)
                        )
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )

export const CreateOrderItem = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(orderActions.apiCreateOrderItem.type),
        switchMap((action: any) => {

            return ajax
                .post(`${API.OrderIdentityEndPoints.CREATE__ORDER_ITEM}`, action.payload, { 'Content-Type': 'application/json' })
                .pipe(
                    mergeMap((response: any) => {
                        let ordersCount = response.response.Body.OrderItems.length - 1;


                        notification.success({
                            message: `Макет добавлен`,
                            description: '',
                            duration: 2
                        })


                        console.log('CreateOrderItem')

                        return of(
                            orderActions.setOrder(response.response.Body),
                            controlActions.setOrderItemIdToStore(response.response.Body.OrderItems[+ordersCount].Id),
                            controlActions.setStorageOrderItemId(response.response.Body.OrderItems[+ordersCount].Id)
                            //orderActions.setOrderItem(response.response.Body.OrderItems[+ordersCount]),
                            //orderActions.setOrderItem(response.response.Body.OrderItems[+ordersCount]),
                        )
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )


export const GetOrderItemById = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(orderActions.apiGetOrderItemById.type),
    switchMap((action: any) => {

        return ajax
            .getJSON(
                `${API.OrderIdentityEndPoints.GET__ORDER_ITEM}${action.payload}`,
                { 'Content-Type': 'application/json' }
            )
            .pipe(
                mergeMap((response: any) => {

                    console.log('GetOrderItemById')
                    return of(
                        orderActions.setOrderItem(response.Body),
                        //controlActions.setImgListToCanvas(response.Body.UsingImages),
                        orderActions.setOrderItemCount(response.Body.Quantity)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)

export const GetOrderById = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(orderActions.apiGetOrderById.type),
    switchMap((action: any) => {

        return ajax
            .getJSON(
                `${API.OrderIdentityEndPoints.GET__ORDER}${action.payload}`,
                { 'Content-Type': 'application/json' }
            )
            .pipe(
                mergeMap((response: any) => {
                    console.log('response GetOrderById')

                    return of(
                        orderActions.setOrder(response.Body)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)

export const AddPictureToOrderItem = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(orderActions.apiAddPictureToOrderItem.type),
        switchMap((action: any) => {

            return ajax
                .post(`${API.OrderIdentityEndPoints.ADD_PICTURE__TO_ORDER_ITEM}${action.payload.orderItemId}&orderId=${action.payload.orderId}`, action.payload.formData)
                .pipe(
                    mergeMap((response: any) => {
                        notification.success({
                            message: `Картинка добавлена`,
                            description: '',
                            duration: 2
                        })
                        
                        return of(
                            orderActions.setOrderItem(response.response.Body),
                            controlActions.setImgToCanvas(response.response.Body.UsingImages[0]),
                            controlActions.setStorageOrderItemId(response.response.Body.Id)
                        )
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )

export const RemovePictureToOrderItem = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(orderActions.apiRemovePictureToOrderItem.type),
        switchMap((action: any) => {

            return ajax
                .post(`${API.OrderIdentityEndPoints.REMOVE_PICTURE__TO_ORDER_ITEM}${action.payload}`, {}, { 'Content-Type': 'application/json' })
                .pipe(
                    mergeMap((response: any) => {

                        notification.success({
                            message: `Картинка удалена`,
                            description: '',
                            duration: 2
                        })

                        console.log(response.response.Body.UsingImages.length)

                        return of(

                            orderActions.setOrderItem(response.response.Body),
                            controlActions.setStorageOrderItemId(response.response.Body.Id)
                        )
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )

export const RemoveOrderItem = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(orderActions.apiRemoveOrderItem.type),
        switchMap((action: any) => {

            return ajax
                .post(`${API.OrderIdentityEndPoints.REMOVE__ORDER_ITEM}${action.payload}`, {}, { 'Content-Type': 'application/json' })
                .pipe(
                    mergeMap((response: any) => {

                        notification.info({
                            message: `Макет удален`,
                            description: '',
                            duration: 2
                        })
                        let _orderItemId = +localStorage.getItem('orderItemId');
                        let _orderItem = new List<OrderItem>(response.response.Body.OrderItems)
                            .where(orderItem => orderItem.Id === _orderItemId).toArray()[0];

                        return of(
                            orderActions.setOrder(response.response.Body),
                            orderActions.setOrderItem(_orderItem),

                            //controlActions.setStorageOrderItemId(response.response.Body.OrderItems[0].Id)
                        )
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )

export const UpdateOrderItem = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(orderActions.apiUpdateOrderItemById.type),
        switchMap((action: any) => {

            return ajax
                .post(`${API.OrderIdentityEndPoints.UPDATE__ORDER_ITEM}`, action.payload,
                    {
                        'Content-Type': 'application/json',
                    }
                )
                .pipe(
                    mergeMap((response: any) => {

                        return of(
                            controlActions.setStorageOrderItemId(response.response.Body.Id),
                            orderActions.setOrderItem(response.response.Body),
                            //productActrions.setProduct(response.response.Body.Quantity)
                            orderActions.setOrderItemCount(response.response.Body.Quantity)
                        )

                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )

export const UpdateOrderItemNotResponse = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(orderActions.apiUpdateNotResponseOrderItemById.type),
        switchMap((action: any) => {
            
            if (action.payload.id === 0 && action.payload.id !== +localStorage.getItem('orderItemId')) {
                
            }
            return ajax
                .post(`${API.OrderIdentityEndPoints.UPDATE__ORDER_ITEM}`, action.payload,
                    {
                        'Content-Type': 'application/json',
                    }
                )
                .pipe(
                    mergeMap((response: any) => {
                        
                        return of(
                            controlActions.setStorageOrderItemId(response.response.Body.Id)

                            //orderActions.setOrderItem(response.response.Body),
                            //orderActions.setOrderItemCount(response.response.Body.Quantity)
                        )

                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )

export const UpdateCanvasOrderItem = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(orderActions.apiUpdateCanvasOrderItemById.type),
        switchMap((action: any) => {
            if (action.payload.id === 0 && action.payload.id !== +localStorage.getItem('orderItemId')) {
                
            }
            return ajax
                .post(`${API.OrderIdentityEndPoints.UPDATE_CANVAS__ORDER_ITEM}${action.payload.id}`, action.payload.body,
                    {
                        'Content-Type': 'application/json',
                    }
                )
                .pipe(
                    mergeMap((response: any) => {
                        
                        return of(
                            controlActions.setStorageOrderItemId(response.response.Body.Id),
                            orderActions.setOrderItem(response.response.Body),
                            //orderActions.apiGetOrderItemById(response.response.Body.Id),
                            orderActions.setOrderItemCount(response.response.Body.Quantity)
                        )
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )



export const ConfirmOrder = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(orderActions.apiConfirmOrder.type),
    switchMap((action: any) => {

        return ajax
            .post(`${API.OrderIdentityEndPoints.CONFIRM__ORDER}`, action.payload, { 'Content-Type': 'application/json' })
            .pipe(
                mergeMap((response: any) => {

                    if (response.status === 200) {
                        localStorage.clear();

                        notification.success({
                            message: `Заказ оформлен`,
                            description: '',
                            duration: 2
                        })

                        window.location.href = routes.COMFIRMED_URI;

                        return of(
                            controlActions.setModalContent(null),
                            //controlActions.closeModal(),
                        )
                    } else {
                        notification.error({
                            message: `Не получилось создать заказ`,
                            description: '',
                            duration: 4
                        })

                        return of(controlActions.closeModal());
                    }
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)



