import { combineEpics } from 'redux-observable';
import * as orderService from './order.service';
import * as productService from './product.service';
import * as categoryService from './category.service';

const epicsArray = [
    ...(Object as any).values(orderService),
    ...(Object as any).values(productService),
    ...(Object as any).values(categoryService)
]

export const epics = combineEpics(...epicsArray);