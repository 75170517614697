import { combineReducers } from "redux";
import { routerReducer } from 'react-router-redux';
import { default as ControlReducer } from './control.slice';
import { default as ProductReducer } from './product.slice';
import { default as OrderReducer } from './order.slice';
import { default as CategoryReducer } from './category.slice';
import { default as CanvasReducer } from './canvas.slice';
import { default as ClientReducer } from './client.slice';
import { default as TextReducer } from './text.slice';
import { default as HistoryReducer } from './history.slice';

export const reducers = combineReducers({
    control: ControlReducer,
    history: HistoryReducer,
    product: ProductReducer,
    order: OrderReducer,
    category: CategoryReducer,
    canvas: CanvasReducer,
    client: ClientReducer,
    text: TextReducer,
    routing: routerReducer
})