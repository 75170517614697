import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { switchMap, mergeMap, catchError } from "rxjs/operators";
import { productActrions } from "../reducers/product.slice";
import { IServiceApplicationState } from "../reducers/application.state";
import { API } from "../constans/api.constants";
import { push } from "react-router-redux";
import { orderActions } from "../reducers/order.slice";

export const GetProductsByCategoryId = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(productActrions.apiGetAllProducts.type),
    switchMap((action: any) => {
        return ajax
            .getJSON(
                `${API.ProductIdentityEndPoints.GET__ALL_PRODUCTS}${action.payload}&isVisible=true`,
                {
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                mergeMap((response: any) => {
                    return of(
                        productActrions.setProducts(response.Body.Products)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)

export const GetProductsListByCategoryId = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(productActrions.apiGetAllProductsList.type),
    switchMap((action: any) => {
          
        return ajax
            .getJSON(
                `${API.ProductIdentityEndPoints.GET__ALL_PRODUCTS}${action.payload}&isVisible=true`,
                {
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                mergeMap((response: any) => {
                    return of(
                        productActrions.setProductsList(response.Body.Products)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)

export const GetProductById = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(productActrions.apiGetProduct.type),
    switchMap((action: any) => {
         
        return ajax
            .getJSON(
                `${API.ProductIdentityEndPoints.GET__PRODUCT_BY_ID}${action.payload}`,
                {
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                mergeMap((response: any) => {
                    
                    return of(
                        productActrions.setProduct(response.Body),
                        //orderActions.setOptionItem(response.Body)
                        push(`/app/product/${response.Body.Id}`)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)

export const GetChangeProductById = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(productActrions.apiGetChangeProduct.type),
    switchMap((action: any) => {

        return ajax
            .getJSON(
                `${API.ProductIdentityEndPoints.GET__PRODUCT_BY_ID}${action.payload}`,
                {
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                mergeMap((response: any) => {

                    return of(
                        productActrions.setChangeProduct(response.Body),
                        //orderActions.setOptionItem(response.Body)
                        push(`/app/product/${response.Body.Id}`)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)

export const GetProductByIdNewOrder = (action$, state$: IServiceApplicationState) => action$.pipe(
    ofType(productActrions.apiGetProductNewOrder.type),
    switchMap((action: any) => {

        return ajax
            .getJSON(
                `${API.ProductIdentityEndPoints.GET__PRODUCT_BY_ID}${action.payload}`,
                {
                    'Content-Type': 'application/json',
                }
            )
            .pipe(
                mergeMap((response: any) => {
                    
                    return of(
                        productActrions.setProductNewOrderItem(response.Body),
                        //orderActions.setOptionItem(response.Body)
                        push(`/app/product/${response.Body.Id}`)
                    )
                }),
                //catchError((error: any) => {
                //    return ErrorHandler(error)
                //})
            )
    })
)