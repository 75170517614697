import * as React from 'react';
import * as routes from './../constans/routes.constants';
import { Switch, Redirect, Route } from 'react-router';
import MasterModule from '../views/master.module';

interface IRouting {
    onEnter: void;
    path: string
}

const Routing: React.FC<IRouting> = (props) => {

    return (
        <Switch>
            <Redirect exact={true} from={'/'} to={`${routes.APP_URI}`} />
            <Route path={`${routes.APP_URI}`} component={MasterModule} />
        </Switch>
    )
}

export default Routing;