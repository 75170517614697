import React, { useEffect } from "react"
import { IconMenuItemColor, IconMenuText, IconImages, IconMenuItemProducts, IconCreateorder } from "../../../constans/api.icons"
import { Tooltip, InputNumber } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { IApplicationState } from "../../../reducers/application.state"
import { Product } from "../../../entities/product/product"
import { TypeOption } from "../../../models/type.option"
import { ProductOption } from "../../../models/productOption"
import { OrderItem } from "../../../models/order.item"
import { Order } from "../../../models/order"
import { controlActions } from "../../../reducers/control.slice"
import { ProductsList } from "../product/products.list"
import { TextView } from "../text/text.view"
import { ColorView } from "../color/color.view"
import { ProductView } from "../product/product.info"
import { orderActions } from "../../../reducers/order.slice"
import { ConfirmOrder } from "../../steps/confirm.order"
import { TextEditView } from "../text/text.edit.view"
import { useHistory, useParams, Route, useLocation } from "react-router";
import { canvasActrions } from "../../../reducers/canvas.slice"
import { round } from "../../../helpers/math.helper"

export const MenuComponent: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const selectedProduct = useSelector<IApplicationState, Product>(state => state.product.product)
    const selectedOrderItem = useSelector<IApplicationState, OrderItem>(state => state.order.selectedOrderItem)

    const orderItemCount = useSelector<IApplicationState, number>(state => state.order.orderItemCount)
    const selectedOrderItemId = useSelector<IApplicationState, number>(state => state.control.orderItemId)
    const orderId = useSelector<IApplicationState, number>(state => state.control.orderId)
    const canvasActiveObject = useSelector<IApplicationState>(state => state.canvas.activeCanvasObject)

    const _storeOrderId = useSelector<IApplicationState, number>(state => state.control.orderId);
    const _storeOrderItemId = useSelector<IApplicationState, number>(state => state.control.orderItemId);

    useEffect(() => {
        console.log(selectedOrderItem);
    }, [_storeOrderItemId])

    //useEffect(() => {
    //    if (selectedOrderItem && selectedOrderItem.Id !== 0) {
    //        dispatch(orderActions.setOrderItemCount(selectedOrderItem.Quantity))
    //    }
    //}, [selectedOrderItem])

    //useEffect(() => {
    //    if (selectedOrderItem && selectedOrderItem.Id !== 0) {
    //        if (selectedOrderItem.Price === 0) {
    //            dispatch(orderActions.apiGetOrderItemById(selectedOrderItem.Id))
    //        }
    //    }
    //}, [selectedOrderItem])


    const uploadImage = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        var f = new FormData();
        f.append("usingImages", file);

        reader.onloadend = () => {
            dispatch(controlActions.setImgToCanvas(file))

            dispatch(orderActions.apiAddPictureToOrderItem({
                orderItemId: _storeOrderItemId,
                orderId: _storeOrderId,
                formData: f
            }))
            //dispatch(projectActions.setImageToPreview({ file: file, imagePreviewUrl: reader.result }))
        }

        reader.readAsDataURL(file)

    }

    const changeOptions = (optionChange: any) => {
         

        if (selectedOrderItem.OrderItemProductOptions.filter(i => i.ProductOptionId === optionChange.productOptionItem.Id).length === 0) {

            const allOptions = selectedOrderItem.OrderItemProductOptions.filter(c => c.ProductOption.Option.TypeOption.Id !== optionChange.typeOptionNetId);

            dispatch(orderActions.apiUpdateOrderItemById(
                {
                    "Id": _storeOrderItemId,
                    "ProductId": selectedProduct.Id,
                    "Quantity": selectedOrderItem.Quantity,
                    "OrderItemProductOptions": [
                        ...allOptions,
                        {
                            "ProductOption": {
                                "Id": optionChange.productOptionItem.Id
                            }
                        }
                    ]
                }
            ))
        }
    }

    const changeCount = (e) => {
       // dispatch(orderActions.setOrderItemCount(+e.target.value))

        dispatch(orderActions.apiUpdateOrderItemById(
            {
                "Id": _storeOrderItemId,
                "ProductId": selectedProduct.Id,
                "Quantity": +e.target.value,
                "OrderItemProductOptions": [
                    ...selectedOrderItem.OrderItemProductOptions,
                ]
            }
        ))
    }

    const changeClass = (optionItem: any) => {
        if (selectedOrderItem.Id !== 0) {
            if (selectedOrderItem.OrderItemProductOptions.filter(f => f.ProductOptionId === optionItem.ProductOptions[0].Id).length !== 0) {
                return 'isSelected'
            }
        }
    }

    const removeOption = (optionItem: any) => {
        let current = selectedOrderItem.OrderItemProductOptions.filter(i => i.ProductOption.Id !== optionItem.productOptionItem.Id);
        dispatch(orderActions.apiUpdateOrderItemById(
            {
                "Id": _storeOrderItemId,
                "ProductId": selectedProduct.Id,
                "Quantity": selectedOrderItem.Quantity,
                "OrderItemProductOptions": [
                    ...current
                ]
            }
        ))


    }

    return (
        <div className="controls__TAB">
            <div className={`tab__ITEM`} onClick={() => { dispatch(controlActions.setModalContent({ Component: <ProductsList />, Size: 'xxl' })) }}>
                <div className="item">
                    <div className="item_icon products">
                        <IconMenuItemProducts />
                    </div>
                    <div className="item_name">
                        Товары
                                    <span className="sub_title">Изменить товар</span>
                    </div>
                </div>
            </div>

            <label className={`tab__ITEM`}>
                <div className="item">
                    <div className="item_icon">
                        <IconImages />
                    </div>
                    <div className="item_name">
                        Загрузить картинку
                                    <span className="sub_title">JPEG, JPG, PNG</span>
                    </div>
                </div>

                <input
                    className="input__ImgUpload"
                    type="file"
                    value={''}
                    onChange={uploadImage}
                />
            </label>


            {canvasActiveObject !== null ?
                <div className={`tab__ITEM`} onClick={() => { dispatch(controlActions.setModalContent({ Component: <TextEditView />, Size: 'xxl' })) }}>
                    <div className="item">
                        <div className="item_icon text">
                            <IconMenuText />
                        </div>
                        <div className="item_name">
                            Изменить текст
                                    <span className="sub_title">Написать свой текст</span>
                        </div>
                    </div>
                </div> :
                <div className={`tab__ITEM`} onClick={() => { dispatch(controlActions.setModalContent({ Component: <TextView />, Size: 'xxl' })) }}>
                    <div className="item">
                        <div className="item_icon text">
                            <IconMenuText />
                        </div>
                        <div className="item_name">
                            Добавить текст
                                    <span className="sub_title">Написать свой текст</span>
                        </div>
                    </div>
                </div>
            }

            <div className={`tab__ITEM`} onClick={() => { dispatch(controlActions.setModalContent({ Component: <ColorView />, Size: 'xxl' })) }}>
                <div className="item">
                    <div className="item_icon color">
                        <IconMenuItemColor />
                    </div>
                    <div className="item_name">
                        Основа и Фон
                                    <span className="sub_title">Цвет продукта</span>
                    </div>
                </div>
            </div>

            <div className="menuLiseStyle"></div>

            <div className={`tab__ITEM`} onClick={() => { dispatch(controlActions.setModalContent({ Component: <ProductView />, Size: 'xxl' })) }}>
                <div className="item">
                    <div className="item_icon color">
                        <IconMenuItemColor />
                    </div>
                    <div className="item_name">
                        Информация о товаре
                                    <span className="sub_title">Цвет продукта</span>
                    </div>
                </div>
            </div>

            <div className="menuLiseStyle"></div>

            <div className="tab__ITEM2">

                <div className="optionGroup__container">
                    <div className="group__TITLE">
                        Кол-во:<br />
                    </div>
                    <div className="o_ITEM_label" style={{ height: '40px' }}>
                        {selectedOrderItem.Id !== 0 ?
                            <Route path={location.pathname} component={() =>
                                <InputNumber min={1} max={10000000}
                                    defaultValue={orderItemCount}
                                    onBlur={changeCount}
                                    style={{ height: '34px', width: '100%' }}
                                />}

                                />: 'Выберите макет'}
                    </div>
                </div>

                {selectedOrderItem.Id !== 0 && selectedProduct.TypeOptions.map((typeOption: TypeOption, index: number) =>
                    <div className="optionGroup__container" key={index}>
                        <div className="group__TITLE">
                            {typeOption.Name}:<br />
                            <b>{selectedProduct.ProductOptions.filter(p => p.Option.Name)[0]}</b>
                        </div>

                        {typeOption.Options.map((optionItem: any, x: number) =>
                            <label className={`o_ITEM_label ${changeClass(optionItem)}`} key={x}>

                                <Tooltip destroyTooltipOnHide={true} placement={"left"} className={`option__ITEM `} title={`${optionItem.Name}`}>

                                    {optionItem.ProductOptions.map((productOptionItem: ProductOption, i: number) => {
                                        if (changeClass(optionItem) === 'isSelected') {
                                            return (
                                                <img key={i} src={productOptionItem.Images[0].ImageUrl} alt={'remove'} onClick={() => removeOption(
                                                    {
                                                        typeOptionNetId: typeOption.NetId,
                                                        productOptionItem: productOptionItem
                                                    }
                                                )} />
                                            )
                                        } else {
                                            return (
                                                <img key={i} src={productOptionItem.Images[0].ImageUrl} alt={''} onClick={() => changeOptions(
                                                    {
                                                        typeOptionNetId: typeOption.Id,
                                                        productOptionItem: productOptionItem
                                                    }
                                                )} />
                                            )
                                        }
                                    }
                                    )}

                                </Tooltip>
                            </label>
                        )}

                    </div>
                )}
            </div>

            <div className="priceOpredr__FRAMES ">

                {selectedOrderItem && selectedOrderItem.Id !== 0 ? <div className="price__ROW">
                    <div>
                        <div className="r__T[">Цена за заготовку:</div>
                        <div className="r__V">
                            {round(selectedOrderItem.TotalProductPrice)}<b>грн</b>
                                        ({round(selectedOrderItem.ProductUnitPrice)}<b>грн/шт</b>)
                                    </div>
                    </div>
                    <div>
                        <div className="r__T">Цена на печать:</div>
                        <div className="r__V">
                            {round(selectedOrderItem.TotalPrintPrice)}<b>грн</b>
                                        ({round(selectedOrderItem.PrintUnitPrice)}<b>грн/шт</b>)
                                    </div>
                    </div>
                    <div>
                        <div className="r__T">Цена за изделие:</div>
                        <div className="r__V">
                            {round(selectedOrderItem.Price)}<b>грн</b>
                                        ({round(selectedOrderItem.UnitPrice)}<b>грн/шт</b>)
                                     </div>
                    </div>
                </div> : null}


                <div className={`tab__ITEM createOrder`}
                    onClick={() => {
                        dispatch(controlActions.saveCanvas(true))
                        dispatch(controlActions.setModalConfirmContent({ Component: <ConfirmOrder />, Size: 'full' }))
                    }}>
                    <div className="item">
                        <div className="item_icon order">
                            <IconCreateorder />
                        </div>
                        <div className="item_name">
                            Заказать
                                    <span className="sub_title">Оформить заказ</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}