import { createSlice } from "@reduxjs/toolkit"

export type TextTypeState = {
    textValue: string,
    textColor: string,
    fontSize: number,
    fontFamily: string,
    fontWeight: string,
    fontStyle: string
}

export const TextState: TextTypeState = {
    textValue:'',
    textColor: '#000',
    fontFamily: 'Aria',
    fontSize: 60,
    fontStyle: 'normal',
    fontWeight: 'normal'
}

const textSlice = createSlice({
    name: 'control',
    initialState: TextState,
    reducers: {
        setTextColor(state, action) {
            state.textColor = action.payload
        },
        setFontFamily(state, action) {
            state.fontFamily = action.payload
        },
        setTextValue(state, action) {
            state.textValue = action.payload
        },
        clearTextFields(state) {
            state.textValue = ''
            state.textColor = '#000'
        }

    }
});

export default textSlice.reducer;
export const textActions = textSlice.actions;