import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../reducers/application.state";
import { Product } from "../../../entities/product/product";
import { Avatar, Table } from "antd";
import { PriceFrame } from "../../../models/price.frame";
import { ProductOption } from "../../../models/productOption";

export const ProductView: React.FC = (props: any) => {
    const dispatch = useDispatch();

    const selectedProduct = useSelector<IApplicationState, Product>(state => state.product.product)

    const columns = [
        {
            title: 'Тираж, шт',
            dataIndex: 'tira',
            
        },
        {
            title: 'Цена товара без печати, грн/шт ',
            dataIndex: 'nprinting',
        },
        {
            title: 'Полноцветная печать в 1 месте (без цены товара), грн/шт ',
            dataIndex: 'price',
        },
    ];

    const data = selectedProduct.PriceFrames.map((item: PriceFrame, index: number) => {
        return {
            key: index,
            tira: `${item.From} - ${item.To}`,
            nprinting: +item.Price.toFixed(2),
            price: +item.PricePrint.toFixed(2),
        }
    });

    return (
        <div className="component__product_PREVIEW">
            <div className="productName"><span>{selectedProduct.Category.Name}</span> - {selectedProduct.Name}</div>
            <div className="picture__ROW">
                <div className="productPicture">
                    {
                        selectedProduct.Image.ImageUrl &&
                        <img src={selectedProduct.Image.ImageUrl} />
                    }
                </div>
                <div className="productColor" style={{ minHeight:100 }}>
                </div>
            </div>
            <div className="price__LVL">
                <Table
                    columns={columns}
                    dataSource={data}
                    size="small"
                    pagination={false} />
            </div>
        </div>
    )
}