import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { IApplicationState } from "../../../reducers/application.state";
import { Product } from "../../../entities/product/product";
import { Category } from "../../../entities/category/category";
import { categoryActrions } from "../../../reducers/category.slice";
import { useParams, useLocation, Route } from "react-router";
import { productActrions } from "../../../reducers/product.slice";
import { orderActions } from "../../../reducers/order.slice";
import { controlActions } from "../../../reducers/control.slice";
import { List } from "linq-typescript";
import { ProductOption } from "../../../models/productOption";
import { canvasActrions } from "../../../reducers/canvas.slice";
import { fabric } from "fabric";
import { Order } from "../../../models/order";

export const NewOrderModalProductsList: React.FC = (props: any) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const category = useSelector<IApplicationState, Category>(state => state.category.selectCategoryNewOrderModal)
    const orderIdStorage = useSelector<IApplicationState, number>(state => state.control.orderId)

    const [colors, setColors] = useState<any>([]);
    const [color, setColor] = useState<any>();
    const [_productItem, setProductItem] = useState<any>();

    useEffect(() => {
        dispatch(controlActions.getStorageOrderId())
    }, [orderIdStorage])


    const setProduct = (productItem: any) => {
        dispatch(canvasActrions.setCanvas(new fabric.Canvas('canvas__ID', {
            selection: false,
            controlsAboveOverlay: true,
            centeredScaling: true,
            allowTouchScrolling: true
        })))

        setTimeout(() => {
            change(productItem)
        }, 600);
    }

    const getColorsList = (productItem) => {
        setProductItem(productItem)
    }

    useEffect(() => {
        if (color) {
            change(_productItem)
        }
    }, [color])

    useEffect(() => {
        if (_productItem) {
            setColors(new List<ProductOption>(_productItem.ProductOptions).where(item => item.Option.TypeOption.IsColor === true).toArray())
        }
    }, [_productItem])

    const change = (productItem: any) => {
        dispatch(productActrions.apiGetProductNewOrder(productItem.Id))

        //dispatch(orderActions.setOrder({ ...order, OrderItems:[]}))
        //let _productOption = new List<ProductOption>(productItem.ProductOptions).where(item => item.Option.TypeOption.IsColor === true).toArray();
        dispatch(productActrions.setProductNewOrderItem({ ...productItem, Colors: colors }))
        // Create New Order

        let data = {
            "OrderId": orderIdStorage,
            "ProductId": productItem.Id,
            "UsingImages": [],
            "OrderItemProductOptions": [
                {
                    "ProductOption": {
                        "Id": color.Id
                    }
                }
            ],
            "Quantity": 1,
        }
        
        dispatch(orderActions.apiCreateOrderItem(data))
        dispatch(orderActions.createOrderItem(false))
    }

    if (colors.length > 0) {
        return (
            <div className="colorView__CONTAINER">
                <div className="">
                    <div className="productColors__ROW">

                        {colors && colors.map((optionItem: ProductOption, index: number) =>
                            <div className="color__ITEM" key={index} onClick={() => { setColor(optionItem) }}>
                                {optionItem.Images && <img src={optionItem.Images[0].ImageUrl} alt={''} />}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="product_LIST_VIEW">
                <div className="scroll__CONTENT">
                    {
                        category.Products.length > 0 ? category.Products.map((productItem: Product, index: number) =>
                            <div className="product_LIST__ITEM" key={index}>
                                <div className="item__CARD">
                                    <div className="item__BOX">
                                        <div className="box__IMG">
                                            <div className="image__WRAPPER">
                                                <div className="image__BOX">
                                                    {productItem.Image && <img src={productItem.Image.ImageUrl} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box__INFO">
                                            <div className="info__NAME">
                                                <span>Наименование</span>
                                                {productItem.Name}
                                            </div>
                                            <div className="info__PRICE">{productItem.Price} грн. / шт</div>
                                            <div className="info__CREATE" onClick={() => setProductItem(productItem)}>Создать</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ) : null
                    }

                </div>
            </div>
        )
    }
}