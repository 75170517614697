import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Product } from "../../../entities/product/product";
import { IApplicationState } from "../../../reducers/application.state";
import { ProductOption } from "../../../models/productOption";
import { controlActions } from "../../../reducers/control.slice";
import { orderActions } from "../../../reducers/order.slice";
import { OrderItem } from "../../../models/order.item";
import { canvasActrions } from "../../../reducers/canvas.slice";

export const ColorView: React.FC = () => {
    const dispatch = useDispatch();

    const selectedProduct = useSelector<IApplicationState, Product>(state => state.product.product)
    const selectedOrderItemId = useSelector<IApplicationState, any>(state => state.control.orderItemId)

    const changeColor = (optionItem: ProductOption) => {

        if (selectedOrderItemId) {
            dispatch(orderActions.setOptionItem(optionItem))
            dispatch(controlActions.setBackgroundImgUrl(optionItem.Images[0].ImageUrl));

            dispatch(controlActions.closeModal())
            dispatch(canvasActrions.setActiveObject(null))
        }
    }

    return (
        <div className="colorView__CONTAINER">
            <div className="">
                <div className="productColors__ROW">

                    {selectedProduct && selectedProduct.Colors.map((optionItem: ProductOption, index: number) =>
                        <div className="color__ITEM" key={index} onClick={() => { changeColor(optionItem) }}>
                            {optionItem.Images && <img src={optionItem.Images[0].ImageUrl} alt={''} />}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}