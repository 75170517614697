import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";

import configure from './store/store.config';
import Routing from './components/routing';
import { AppInit } from './helpers/app.init'

//CSS
import './assets/scss/main.scss';
import 'antd/dist/antd.css';

const history = createBrowserHistory();
const initialState = (window as any).initialRedusState;
const store = configure(history, initialState);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Routing path='/' onEnter={AppInit(store)} />
        </Router>
    </Provider>,
    document.getElementById('root')
)


//const App = () => {
//    return (
//        <Router history={createBrowserHistory()}>
//            <Switch>
//                <Route path="/" component={MasterView} />
//            </Switch>
//        </Router>
//    )
//}

{ /*
 const App = () => {
    return (
        <div className="constructor__CORE">
            <div className="container__CORE">
                <Router history={createBrowserHistory()}>
                    <Switch>
                        <Route path="/" component={DesignCanvas} />
                        <Route path={`${routes.Root}/:categoryName`} component={DesignCanvas} />
                        <Route path={`${routes.Root}/:categoryName/:subCategoryName`} component={DesignCanvas} />
                        <Route path={`${routes.Root}/:categoryName/:subCategoryName/:productName/:id`} component={DesignCanvas} />
                    </Switch>
                </Router>
            </div>
        </div>
    )
}
 */}

//render(<App />, document.getElementById('root'))

//serviceWorker.unregister();
