import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Route } from "react-router";
import { productActrions } from "../../reducers/product.slice";
import { IApplicationState } from "../../reducers/application.state";
import { Product } from "../../entities/product/product";
import { fabric } from "fabric";
import { canvasActrions } from "../../reducers/canvas.slice";
import { MenuComponent } from "./menu/core.menu";
import { Order } from "../../models/order";
import { orderActions } from "../../reducers/order.slice";
import { controlActions } from "../../reducers/control.slice";
import { OrderList } from "./order.list/order.list";
import { OrderItem } from "../../models/order.item";
import { Drawer } from "antd";
import { HistoryView } from "./menu/history";
import { historyActrions } from "../../reducers/history.slice";
import { ProductOption } from "../../models/productOption";
import { ImageDefault } from "../../models/image";
import { ConfirmOrder } from "../steps/confirm.order";
import { CanvasIcon__Collapse, CanvasIcon__EditText, CanvasIcon__Remove, CanvasIcon__ResizeX, CanvasIcon__ResizeXY, CanvasIcon__ResizeY, CanvasIcon__Rotate, IconHistoryText } from "../../constans/api.icons";
import { debug } from "console";
import { TextEditView } from "./text/text.edit.view";
import { List } from "linq-typescript";
import { JsxEmit } from "typescript";
import { OrderItemProductOption } from "../../models/order.item.product.option";

export const ConstructorCore: React.FC = (props: any) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const uriParams: { productId } = useParams()
    const productId: number = uriParams.productId;
    const _urlProductId: number = uriParams.productId;

    const canvasFrame = useSelector<IApplicationState, fabric.Canvas>(state => state.canvas.canvas)
    const selectedProduct = useSelector<IApplicationState, Product>(state => state.product.product)
    const newOrderItemProduct = useSelector<IApplicationState, Product>(state => state.product.selectedProductNewOrderItem)
    const order = useSelector<IApplicationState, Order>(state => state.order.order)
    const selectedOrderItem = useSelector<IApplicationState, OrderItem>(state => state.order.selectedOrderItem)
    const selectedOrderItemId = useSelector<IApplicationState, any>(state => state.control.orderItemId)
    const orderIdStorage = useSelector<IApplicationState, any>(state => state.control.orderId)

    const modalContent = useSelector<IApplicationState, any>(state => state.control.modalContent)
    const modalConfirmContent = useSelector<IApplicationState, any>(state => state.control.modalConfirmContent)

    const isOpenModal = useSelector<IApplicationState, boolean>(state => state.control.isOpenModal)
    const isOpenConfirmModal = useSelector<IApplicationState, boolean>(state => state.control.isOpenConfirmModal)

    const backgroundImgUrl = useSelector<IApplicationState, string>(state => state.control.backgroundImgUrl)
    const productOption = useSelector<IApplicationState, ProductOption>(state => state.order.optionItem)

    const imgToCanvas = useSelector<IApplicationState, any>(state => state.control.imgToCanvas)

    const inSaveCanvas = useSelector<IApplicationState, boolean>(state => state.control.saveCanvas)

    // new 26 07 2021
    const _storeOrderId = useSelector<IApplicationState, number>(state => state.control.orderId);
    const _storeOrderItemId = useSelector<IApplicationState, number>(state => state.control.orderItemId);
    const _product = useSelector<IApplicationState, Product>(state => state.product.product);
    const _order = useSelector<IApplicationState, Order>(state => state.order.order);
    const _orderItem = useSelector<IApplicationState, OrderItem>(state => state.order.selectedOrderItem);

    //ICON
    const iconRotate = new Image();
    iconRotate.src = CanvasIcon__Rotate;

    const iconResizeX = new Image();
    iconResizeX.src = CanvasIcon__ResizeX;

    const iconResizeY = new Image();
    iconResizeY.src = CanvasIcon__ResizeY;

    const iconResizeXY = new Image();
    iconResizeXY.src = CanvasIcon__ResizeXY;

    const iconResizeCollapse = new Image();
    iconResizeCollapse.src = CanvasIcon__Collapse;

    const iconRemove = new Image();
    iconRemove.src = CanvasIcon__Remove;

    const iconEditText = new Image();
    iconEditText.src = CanvasIcon__EditText;

    // Create canvas frame + get Product
    useEffect(() => {
        dispatch(controlActions.getStorageOrderId());
        dispatch(controlActions.getStorageOrderItemId());
        dispatch(productActrions.apiGetProduct(_urlProductId))

        console.log('Start')

        dispatch(canvasActrions.setCanvas(new fabric.Canvas('canvas__ID', {
            selection: false,
            controlsAboveOverlay: true,
            centeredScaling: true,
            allowTouchScrolling: true
        })));

        return () => {
            dispatch(orderActions.setOrder(new Order))
            dispatch(orderActions.setOptionItem(new OrderItem()))
        }
    }, []);

    // Create ORDER
    useEffect(() => {
        if ((_storeOrderId === 0 && _product.Id > 0)) {

            dispatch(orderActions.apiCreateOrder(_product))
        }
    }, [_product]);

    // Get Order
    useEffect(() => {
        if (_storeOrderId > 0) {
            console.log('U _storeOrderId')

            dispatch(orderActions.apiGetOrderById(_storeOrderId))

            if (_storeOrderItemId > 0) {
                console.log('U _storeOrderItemId')

                dispatch(orderActions.apiGetOrderItemById(_storeOrderItemId));
                //[_storeOrderItemId]
            }
        } else if ((_storeOrderId === 0 && _product.Id > 0) && (_order && _order.OrderItems.length === 0)) {
            console.log('U _storeOrderId')


            dispatch(orderActions.apiCreateOrder(_product))
        }
    }, [_storeOrderId]);

    // Get Order Item
    useEffect(() => {
        if (_storeOrderItemId > 0) {
            console.log('U _storeOrderItemId')

            dispatch(orderActions.apiGetOrderItemById(_storeOrderItemId));
            //[_storeOrderItemId]
        } else {

        }
    }, [_storeOrderItemId]);

    useEffect(() => {
        if (_orderItem.Id > 0) {
            // console.log('U _orderItem')
        }
    }, [_orderItem])

    //set Background Img
    useEffect(() => {
        if (backgroundImgUrl !== '') {
            console.log('U backgroundImgUrl')
            setBackgroundImg(backgroundImgUrl)
        }
    }, [backgroundImgUrl])

    useEffect(() => {
        if (_urlProductId !== _product.Id) {
            dispatch(productActrions.apiGetProduct(_urlProductId))
        }
    }, [_urlProductId])

    // Canvas Handler
    useEffect(() => {

        if (canvasFrame) {
            console.log('U canvasFrame')
            let workFrame__CONTAINER: HTMLElement = document.getElementById('workFrame__CONTAINER__ID');
            if (workFrame__CONTAINER) {
                canvasFrame.setHeight(workFrame__CONTAINER.clientHeight);
                canvasFrame.setWidth(workFrame__CONTAINER.clientWidth);
            }

            canvasFrame.on(
                'mouse:up:before', handleControl,
            )
        }
    }, [canvasFrame])

    useEffect(() => {
        if (_orderItem.Id > 0) {

            if (canvasFrame && _orderItem.Canvas && _orderItem.Canvas !== '{"version":"4.2.0","objects":[]}' && _orderItem.Canvas !== null) {
                orderActions.setOrderItemCount(_orderItem.Quantity)
                // dispatch(canvasActrions.renderAll())

                //canvasFrame.renderAll();
                canvasFrame.clear().loadFromJSON(JSON.parse(_orderItem.Canvas), function () {
                    canvasFrame.renderAll();
                    dispatch(historyActrions.setTextList(canvasFrame.getObjects('i-text')))
                    dispatch(historyActrions.setImgListI(canvasFrame.getObjects('image')))
                })

            } else {
                if (_orderItem.Canvas === null && _product && _product.Colors.length > 0 && _orderItem.Id) {
                    setTimeout(function () {
                    }, 400);
                    let optionItem: ProductOption;

                    //let optionItem: ProductOption = _product.Colors[0]
                    let _OrderItemProductOption = new List<OrderItemProductOption>(_orderItem.OrderItemProductOptions).where(item => item.ProductOption.Option.TypeOption.IsColor === true).toArray();

                    if (_OrderItemProductOption.length) {
                        optionItem = _OrderItemProductOption[0].ProductOption;
                    } else {
                        optionItem = _product.Colors[0];
                    }

                    dispatch(orderActions.setOptionItem(optionItem))

                    fabric.Image.fromURL(optionItem.Images[0].ImageUrl, (img) => {
                        let imgSizeW = img.width;
                        let imgSizeH = img.height;
                        let canSizeW = canvasFrame.getWidth();
                        let canSizeH = canvasFrame.getHeight();
                        let imgCoeff = imgSizeH / imgSizeW;

                        if (canSizeH < imgSizeH) {
                            if (canSizeW < imgSizeW) {
                                let wCoeff = canSizeW / imgSizeW;
                                let hCoeff = canSizeH / imgSizeH;
                                if (wCoeff < hCoeff) {
                                    img.scale(wCoeff);
                                } else {
                                    img.scale(hCoeff);
                                }
                            } else {
                                img.scale(canSizeH / imgSizeH);
                            }
                        } else if (canSizeW < imgSizeW) {
                            img.scale(canSizeW / imgSizeW);
                        }

                        canvasFrame.centerObject(img);
                        canvasFrame.setBackgroundImage(img, canvasFrame.renderAll.bind(canvasFrame));
                        //dispatch(canvasActrions.renderAll())

                        dispatch(orderActions.apiUpdateCanvasOrderItemById(
                            {
                                id: _storeOrderItemId,
                                body: {
                                    'Canvas': JSON.stringify(canvasFrame.toJSON()),
                                    'Preview': canvasFrame.toSVG(),
                                }
                            }
                        ))

                        let gg = {
                            "Id": _storeOrderItemId,
                            "ProductId": _product.Id,
                            "Quantity": _orderItem.Quantity,
                            "OrderItemProductOptions": [
                                ..._orderItem.OrderItemProductOptions,
                                {
                                    "ProductOption": {
                                        "Id": optionItem.Id
                                    }
                                }
                            ]
                        }
                        
                        dispatch(orderActions.apiUpdateOrderItemById(gg))
                    })
                }
            }

            fabric.Object.prototype.transparentCorners = false;
            fabric.Object.prototype.cornerColor = 'blue';
            fabric.Object.prototype.cornerStyle = 'circle';
            fabric.Object.prototype.padding = 10;

            const fab: any = fabric;
            const fabObProto: any = fabric.Object.prototype;

            const fabricControl: any = fab.Control;
            const fabricControls: any = fabObProto.controls;

            fabricControls.customMove = new fabricControl({
                x: 0.5,
                y: -0.5,
                offsetX: 22,
                //actionHandler: move?,
                cursorStyle: 'pointer',
                cornerSize: 24,
                mouseUpHandler: deleteObject,
                actionName: 'drag',
                render: (ctx, left, top, styleOverride, fabricObject,) => renderIcon(ctx, left, top, styleOverride, fabricObject, iconRemove, '')
            });

            fabricControls.customTextEdit = new fabricControl({
                x: -0.5,
                y: -0.5,
                offsetX: -22,
                //actionHandler: move?,
                cursorStyle: 'pointer',
                cornerSize: 24,
                mouseUpHandler: editTextObject,
                actionName: 'editText',
                render: (ctx, left, top, styleOverride, fabricObject) => renderIcon(ctx, left, top, styleOverride, fabricObject, iconEditText, 'editText')
            });

            fabric.Object.prototype.drawControls = function (ctx, styleOverride) {
                styleOverride = styleOverride || {};
                ctx.save();
                ctx.setTransform(this.canvas.getRetinaScaling(), 0, 0, this.canvas.getRetinaScaling(), 0, 0);
                ctx.strokeStyle = ctx.fillStyle = styleOverride.cornerColor || this.cornerColor;
                if (!this.transparentCorners) {
                    ctx.strokeStyle = styleOverride.cornerStrokeColor || this.cornerStrokeColor;
                }
                this._setLineDash(ctx, styleOverride.cornerDashArray || this.cornerDashArray, null);
                this.setCoords();
                this.forEachControl(function (control, key, fabricObject) {

                    if (control.getVisibility(fabricObject, key)) {
                        switch (key) {
                            case 'mtr':
                                ctx.drawImage(
                                    iconRotate,
                                    fabricObject.oCoords[key].x - 16,
                                    fabricObject.oCoords[key].y - 16,
                                    32,
                                    32
                                )
                                break;
                            case 'customMove':
                                // 
                                //control.render(
                                //    ctx,
                                //    fabricObject.oCoords[key].x,
                                //    fabricObject.oCoords[key].y,
                                //    styleOverride,
                                //    fabricObject
                                //);
                                break;
                            //case 'ml':
                            //    ctx.drawImage(
                            //        iconResizeX,
                            //        fabricObject.oCoords[key].x - 16,
                            //        fabricObject.oCoords[key].y - 16,
                            //        32,
                            //        32
                            //    )
                            //    break;
                            //case 'mb':
                            //    ctx.drawImage(
                            //        iconResizeY,
                            //        fabricObject.oCoords[key].x - 16,
                            //        fabricObject.oCoords[key].y - 16,
                            //        32,
                            //        32
                            //    )
                            //    break;

                            //case 'bl':
                            //    ctx.drawImage(
                            //        iconResizeXY,
                            //        fabricObject.oCoords[key].x - 14,
                            //        fabricObject.oCoords[key].y - 14,
                            //        28,
                            //        28
                            //    )
                            //    break;
                            default:
                                break;
                        }

                        control.render(
                            ctx,
                            fabricObject.oCoords[key].x,
                            fabricObject.oCoords[key].y,
                            styleOverride,
                            fabricObject
                        );
                    }
                });

                ctx.restore();
                return this;
            }
        }

    }, [_orderItem])


















    //useEffect(() => {
    //    dispatch(controlActions.getStorageOrderId())

    //    if (productId !== null) {
    //        if (+productId !== selectedOrderItem.ProductId && selectedOrderItem.Id !== 0) {
    //            dispatch(productActrions.apiGetProduct(selectedOrderItem.ProductId))
    //        } else {
    //            dispatch(productActrions.apiGetProduct(+productId))
    //        }
    //    }

    //    dispatch(canvasActrions.setCanvas(new fabric.Canvas('canvas__ID', {
    //        selection: false,
    //        controlsAboveOverlay: true,
    //        centeredScaling: true,
    //        allowTouchScrolling: true
    //    })))


    //    if (!!orderIdStorage) {
    //        dispatch(orderActions.apiGetOrderById(orderIdStorage))
    //    }

    //    return () => {
    //        dispatch(orderActions.setOrderItem(new OrderItem))
    //    }

    //}, [])

    const deleteObject = (eventData, element) => {

        let canvas = element.canvas;

        if (element.type === 'i-text') {
            canvas.setActiveObject(element);
            canvas.remove(canvas._activeObject);
            canvas.requestRenderAll();
        } else if (element.type === 'image') {
            element.set({
                width: 0,
                height: 0
            })
            canvas.setActiveObject(element);
            canvas.remove(element);

            canvas.requestRenderAll();
            dispatch(canvasActrions.renderAll())

            let picture = selectedOrderItem.UsingImages.filter(imgItem => imgItem.ImageUrl === element.src)[0];

            if (picture) {
                dispatch(orderActions.apiRemovePictureToOrderItem(picture.Id))
            }

            dispatch(orderActions.apiUpdateCanvasOrderItemById(
                {
                    id: _storeOrderItemId,
                    body: {
                        'Canvas': JSON.stringify(canvas.toJSON()),
                        'Preview': canvas.toSVG(),
                    }
                }
            ))

        }

        //let target = eventData.target;
        //canvasFrame.remove(target);
        //canvasFrame.requestRenderAll();
    }

    const editTextObject = (eventData, element) => {
        let canvas = element.canvas;

        canvas.setActiveObject(element);
        canvas.requestRenderAll();
        if (canvas._activeObject && element.type === 'i-text') {
            dispatch(canvasActrions.setActiveObject(element));
            dispatch(controlActions.setModalContent({ Component: <TextEditView />, Size: 'xxl' }))
        }
    }

    const renderIcon = (ctx, left, top, styleOverride, fabricObject, img, actionName?: string) => {
        if (actionName === 'editText' && fabricObject.type !== 'i-text') {
            return;
        }

        let size = 30;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -size / 2, -size / 2, size, size);

        ctx.restore();
    }

    //useEffect(() => {
    //    if ((+localStorage.getItem('orderItemId') !== selectedOrderItem.Id) && (order.OrderItems && order.OrderItems.length > 0)) {
    //        let _selected = new List<OrderItem>(order.OrderItems)
    //            .where(item => item.Id === +localStorage.getItem('orderItemId')).toArray()[0];
    //        
    //        if (_selected) {
    //            console.log(_selected)
    //            
    //            if ((_selected.Canvas === null || _selected.Preview === null)) {
    //                let _productOption = new List<ProductOption>(_selected.Product.ProductOptions).where(item => item.Option.TypeOption.IsColor === true).toArray();
    //                

    //                if (newOrderItemProduct.Id === _selected.Product.Id) {
    //                    
    //                    setTimeout(() => {
    //                        setBackgroundImg(newOrderItemProduct.Colors[0].Images ? newOrderItemProduct.Colors[0].Images[0].ImageUrl : _productOption[0].Images[0].ImageUrl);
    //                    }, 500)
    //                }
    //            }
    //        }
    //    }
    //}, [order.OrderItems, newOrderItemProduct])

    //useEffect(() => {
    //    if (selectedProduct.Id !== 0 && foo.current === false) {
    //        foo.current = true;
    //        let id = +localStorage.getItem('orderItemId');
    //        console.log('======');
    //        if (order.Id === 0 && selectedProduct.Id !== 0 && orderIdStorage === 0) {
    //            dispatch(orderActions.apiCreateOrder(selectedProduct))
    //        }

    //        if (selectedOrderItem.Canvas && selectedProduct && selectedProduct.Colors.length > 0 && selectedOrderItem.Id === id) {
    //            //let json: fabric.Canvas = JSON.parse(selectedOrderItem.Canvas);
    //            //let optionItem: ProductOption = selectedProduct.Colors[0];
    //            //let backgroundImage: any = json.backgroundImage;
    //        } else {
    //            if (selectedOrderItem.Canvas === null && selectedProduct && selectedProduct.Colors.length > 0 && selectedOrderItem.Id === id) {
    //                setTimeout(function () {
    //                    let optionItem: ProductOption = selectedProduct.Colors[0]
    //                    dispatch(orderActions.setOptionItem(optionItem))

    //                    fabric.Image.fromURL(optionItem.Images[0].ImageUrl, (img) => {
    //                        let imgSizeW = img.width;
    //                        let imgSizeH = img.height;
    //                        let canSizeW = canvasFrame.getWidth();
    //                        let canSizeH = canvasFrame.getHeight();
    //                        let imgCoeff = imgSizeH / imgSizeW;

    //                        if (canSizeH < imgSizeH) {
    //                            if (canSizeW < imgSizeW) {
    //                                let wCoeff = canSizeW / imgSizeW;
    //                                let hCoeff = canSizeH / imgSizeH;
    //                                if (wCoeff < hCoeff) {
    //                                    img.scale(wCoeff);
    //                                } else {
    //                                    img.scale(hCoeff);
    //                                }
    //                            } else {
    //                                img.scale(canSizeH / imgSizeH);
    //                            }
    //                        } else if (canSizeW < imgSizeW) {
    //                            img.scale(canSizeW / imgSizeW);
    //                        }

    //                        canvasFrame.centerObject(img);
    //                        canvasFrame.setBackgroundImage(img, canvasFrame.renderAll.bind(canvasFrame));
    //                        dispatch(canvasActrions.renderAll())

    //                        dispatch(orderActions.apiUpdateCanvasOrderItemById(
    //                            {
    //                                id: selectedOrderItem.Id,
    //                                body: {
    //                                    'Canvas': JSON.stringify(canvasFrame.toJSON()),
    //                                    'Preview': canvasFrame.toSVG(),
    //                                }
    //                            }
    //                        ))

    //                        dispatch(orderActions.apiUpdateOrderItemById(
    //                            {
    //                                "Id": selectedOrderItem.Id,
    //                                "ProductId": selectedProduct.Id,
    //                                "Quantity": selectedOrderItem.Quantity,
    //                                "OrderItemProductOptions": [
    //                                    ...selectedOrderItem.OrderItemProductOptions,
    //                                    {
    //                                        "ProductOption": {
    //                                            "Id": optionItem.Id
    //                                        }
    //                                    }
    //                                ]
    //                            }
    //                        ))

    //                    })


    //                }, 400);
    //            }
    //        }


    //    }
    //}, [selectedProduct])

    //useEffect(() => {
    //    if (order.Id !== 0 && isOpenConfirmModal === true) {
    //        let id = +localStorage.getItem('orderItemId');
    //        let canvas = canvasFrame;
    //        dispatch(canvasActrions.setActiveObject(null))
    //        dispatch(orderActions.apiUpdateCanvasOrderItemById(
    //            {
    //                id: id,
    //                body: {
    //                    'Canvas': JSON.stringify(canvas.toJSON()),
    //                    'Preview': canvas.toSVG(),
    //                }
    //            }
    //        ))
    //    }
    //}, [isOpenConfirmModal])

    //useEffect(() => {
    //    if (order.Id !== 0 && inSaveCanvas) {

    //        let id = +localStorage.getItem('orderItemId');
    //        let canvas = canvasFrame;
    //        dispatch(canvasActrions.setActiveObject(null))
    //        dispatch(orderActions.apiUpdateCanvasOrderItemById(
    //            {
    //                id: id,
    //                body: {
    //                    'Canvas': JSON.stringify(canvas.toJSON()),
    //                    'Preview': canvas.toSVG(),
    //                }
    //            }
    //        ))
    //        dispatch(controlActions.setModalConfirmContent({ Component: <ConfirmOrder />, Size: 'full' }))
    //    }

    //}, [inSaveCanvas])

    //useEffect(() => {
    //    let id = +localStorage.getItem('orderItemId');
    //    if (selectedOrderItem.Id === 0) {
    //        if (id && id > 0) {
    //            dispatch(orderActions.apiGetOrderItemById(id))
    //        }

    //    } else {
    //        if (productId !== null) {
    //            if (+productId !== selectedOrderItem.ProductId) {
    //                dispatch(productActrions.apiGetProduct(selectedOrderItem.ProductId))
    //            } else {
    //                dispatch(productActrions.apiGetProduct(+productId))
    //            }
    //        }

    //        if (canvasFrame && selectedOrderItem.Canvas && selectedOrderItem.Canvas !== '{"version":"4.2.0","objects":[]}' && selectedOrderItem.Canvas !== null) {
    //            orderActions.setOrderItemCount(selectedOrderItem.Quantity)
    //            dispatch(canvasActrions.renderAll())

    //            canvasFrame.renderAll();
    //            canvasFrame.clear().loadFromJSON(JSON.parse(selectedOrderItem.Canvas), function () {
    //                canvasFrame.renderAll();
    //                dispatch(historyActrions.setTextList(canvasFrame.getObjects('i-text')))
    //                dispatch(historyActrions.setImgListI(canvasFrame.getObjects('image')))
    //            })

    //        } else {
    //            if (selectedOrderItem.Canvas === null && selectedProduct && selectedProduct.Colors.length > 0 && selectedOrderItem.Id === id) {
    //                setTimeout(function () {
    //                    let optionItem: ProductOption = selectedProduct.Colors[0]
    //                    dispatch(orderActions.setOptionItem(optionItem))

    //                    fabric.Image.fromURL(optionItem.Images[0].ImageUrl, (img) => {
    //                        let imgSizeW = img.width;
    //                        let imgSizeH = img.height;
    //                        let canSizeW = canvasFrame.getWidth();
    //                        let canSizeH = canvasFrame.getHeight();
    //                        let imgCoeff = imgSizeH / imgSizeW;

    //                        if (canSizeH < imgSizeH) {
    //                            if (canSizeW < imgSizeW) {
    //                                let wCoeff = canSizeW / imgSizeW;
    //                                let hCoeff = canSizeH / imgSizeH;
    //                                if (wCoeff < hCoeff) {
    //                                    img.scale(wCoeff);
    //                                } else {
    //                                    img.scale(hCoeff);
    //                                }
    //                            } else {
    //                                img.scale(canSizeH / imgSizeH);
    //                            }
    //                        } else if (canSizeW < imgSizeW) {
    //                            img.scale(canSizeW / imgSizeW);
    //                        }

    //                        canvasFrame.centerObject(img);
    //                        canvasFrame.setBackgroundImage(img, canvasFrame.renderAll.bind(canvasFrame));
    //                        dispatch(canvasActrions.renderAll())

    //                        dispatch(orderActions.apiUpdateCanvasOrderItemById(
    //                            {
    //                                id: selectedOrderItem.Id,
    //                                body: {
    //                                    'Canvas': JSON.stringify(canvasFrame.toJSON()),
    //                                    'Preview': canvasFrame.toSVG(),
    //                                }
    //                            }
    //                        ))

    //                        dispatch(orderActions.apiUpdateOrderItemById(
    //                            {
    //                                "Id": +selectedOrderItemId,
    //                                "ProductId": selectedProduct.Id,
    //                                "Quantity": selectedOrderItem.Quantity,
    //                                "OrderItemProductOptions": [
    //                                    ...selectedOrderItem.OrderItemProductOptions,
    //                                    {
    //                                        "ProductOption": {
    //                                            "Id": optionItem.Id
    //                                        }
    //                                    }
    //                                ]
    //                            }
    //                        ))
    //                    })


    //                }, 400);
    //            }
    //        }
    //    }

    //    if (selectedOrderItem.Id > 0) {
    //        fabric.Object.prototype.transparentCorners = false;
    //        fabric.Object.prototype.cornerColor = 'blue';
    //        fabric.Object.prototype.cornerStyle = 'circle';
    //        fabric.Object.prototype.padding = 10;

    //        const fab: any = fabric;
    //        const fabObProto: any = fabric.Object.prototype;

    //        const fabricControl: any = fab.Control;
    //        const fabricControls: any = fabObProto.controls;

    //        fabricControls.customMove = new fabricControl({
    //            x: 0.5,
    //            y: -0.5,
    //            offsetX: 22,
    //            //actionHandler: move?,
    //            cursorStyle: 'pointer',
    //            cornerSize: 24,
    //            mouseUpHandler: deleteObject,
    //            actionName: 'drag',
    //            render: (ctx, left, top, styleOverride, fabricObject,) => renderIcon(ctx, left, top, styleOverride, fabricObject, iconRemove, '')
    //        });

    //        fabricControls.customTextEdit = new fabricControl({
    //            x: -0.5,
    //            y: -0.5,
    //            offsetX: -22,
    //            //actionHandler: move?,
    //            cursorStyle: 'pointer',
    //            cornerSize: 24,
    //            mouseUpHandler: editTextObject,
    //            actionName: 'editText',
    //            render: (ctx, left, top, styleOverride, fabricObject) => renderIcon(ctx, left, top, styleOverride, fabricObject, iconEditText, 'editText')
    //        });
    //        
    //        fabric.Object.prototype.drawControls = function (ctx, styleOverride) {
    //            styleOverride = styleOverride || {};
    //            ctx.save();
    //            ctx.setTransform(this.canvas.getRetinaScaling(), 0, 0, this.canvas.getRetinaScaling(), 0, 0);
    //            ctx.strokeStyle = ctx.fillStyle = styleOverride.cornerColor || this.cornerColor;
    //            if (!this.transparentCorners) {
    //                ctx.strokeStyle = styleOverride.cornerStrokeColor || this.cornerStrokeColor;
    //            }
    //            this._setLineDash(ctx, styleOverride.cornerDashArray || this.cornerDashArray, null);
    //            this.setCoords();
    //            this.forEachControl(function (control, key, fabricObject) {

    //                if (control.getVisibility(fabricObject, key)) {
    //                    switch (key) {
    //                        case 'mtr':
    //                            ctx.drawImage(
    //                                iconRotate,
    //                                fabricObject.oCoords[key].x - 16,
    //                                fabricObject.oCoords[key].y - 16,
    //                                32,
    //                                32
    //                            )
    //                            break;
    //                        case 'customMove':
    //                            // 
    //                            //control.render(
    //                            //    ctx,
    //                            //    fabricObject.oCoords[key].x,
    //                            //    fabricObject.oCoords[key].y,
    //                            //    styleOverride,
    //                            //    fabricObject
    //                            //);
    //                            break;
    //                        //case 'ml':
    //                        //    ctx.drawImage(
    //                        //        iconResizeX,
    //                        //        fabricObject.oCoords[key].x - 16,
    //                        //        fabricObject.oCoords[key].y - 16,
    //                        //        32,
    //                        //        32
    //                        //    )
    //                        //    break;
    //                        //case 'mb':
    //                        //    ctx.drawImage(
    //                        //        iconResizeY,
    //                        //        fabricObject.oCoords[key].x - 16,
    //                        //        fabricObject.oCoords[key].y - 16,
    //                        //        32,
    //                        //        32
    //                        //    )
    //                        //    break;

    //                        //case 'bl':
    //                        //    ctx.drawImage(
    //                        //        iconResizeXY,
    //                        //        fabricObject.oCoords[key].x - 14,
    //                        //        fabricObject.oCoords[key].y - 14,
    //                        //        28,
    //                        //        28
    //                        //    )
    //                        //    break;
    //                        default:
    //                            break;
    //                    }

    //                    control.render(
    //                        ctx,
    //                        fabricObject.oCoords[key].x,
    //                        fabricObject.oCoords[key].y,
    //                        styleOverride,
    //                        fabricObject
    //                    );
    //                }
    //            });

    //            ctx.restore();
    //            return this;
    //        }
    //    }

    //}, [selectedOrderItem])

    //useEffect(() => {
    //    if (order.Id === 0) {
    //        let id = localStorage.getItem('orderId');
    //        if (id) {
    //            dispatch(orderActions.apiGetOrderById(id))
    //        }
    //    }
    //}, [order])


    useEffect(() => {

        if (imgToCanvas !== '' && imgToCanvas !== null && !imgToCanvas.type) {
            addImgToCanvas(imgToCanvas)
        }
    }, [imgToCanvas])

    const setBackgroundImg = async (imgUrl: string) => {

        fabric.Image.fromURL(imgUrl, (img) => {
            let imgSizeW = img.width;
            let imgSizeH = img.height;
            let canSizeW = canvasFrame.getWidth();
            let canSizeH = canvasFrame.getHeight();
            let imgCoeff = imgSizeH / imgSizeW;

            if (canSizeH < imgSizeH) {
                if (canSizeW < imgSizeW) {
                    let wCoeff = canSizeW / imgSizeW;
                    let hCoeff = canSizeH / imgSizeH;
                    if (wCoeff < hCoeff) {
                        img.scale(wCoeff);
                    } else {
                        img.scale(hCoeff);
                    }
                } else {
                    img.scale(canSizeH / imgSizeH);
                }
            } else if (canSizeW < imgSizeW) {
                img.scale(canSizeW / imgSizeW);
            }

            canvasFrame.centerObject(img);
            canvasFrame.setBackgroundImage(img, canvasFrame.renderAll.bind(canvasFrame));
            canvasFrame.renderAll()
            //dispatch(canvasActrions.renderAll())

            dispatch(orderActions.apiUpdateCanvasOrderItemById(
                {
                    id: _storeOrderItemId,
                    body: {
                        'Canvas': JSON.stringify(canvasFrame.toJSON()),
                        'Preview': canvasFrame.toSVG(),
                    }
                }
            ))

        })


        let pOption;
        if (!productOption) {
            pOption = _product.Colors[0]
        } else {
            pOption = productOption;
        }

        //let _selectedOrderItemId = (selectedOrderItemId && selectedOrderItem.Id === 0) ? _storeOrderItemId : selectedOrderItem.Id;

        let _data = {
            "Id": _storeOrderItemId,
            "ProductId": _product.Id,
            "Quantity": _orderItem.Quantity,
            "OrderItemProductOptions": [
                ..._orderItem.OrderItemProductOptions,
                {
                    "ProductOption": {
                        "Id": pOption.Id
                    }
                }
            ]
        }
        
        await dispatch(orderActions.apiUpdateNotResponseOrderItemById(_data))
    }

    const addImgToCanvas = (img: ImageDefault) => {
        dispatch(controlActions.getStorageOrderItemId());

        if (img !== null) {
            fabric.Image.fromURL(img.ImageUrl, (img) => {

                img.crossOrigin = ""

                if (img.width > canvasFrame.width) {
                    let scale = (canvasFrame.width - 200) / img.width;
                    img.scale(scale).set({
                        transparentCorners: false,
                    });
                } else {
                    img.scale(0.5).set({
                        transparentCorners: false,
                    });
                }

                canvasFrame.centerObject(img);
                canvasFrame.setActiveObject(img).add(img);

                dispatch(canvasActrions.renderAll())

                dispatch(orderActions.apiUpdateCanvasOrderItemById(
                    {
                        id: _storeOrderItemId,
                        body: {
                            'Canvas': JSON.stringify(canvasFrame.toJSON()),
                            'Preview': canvasFrame.toSVG(),
                        }
                    }
                ))
            });

        }

    }

    const handleControl = (e) => {
        dispatch(controlActions.getStorageOrderItemId());
        console.log('handleControl');

        if (e.targe === null) {
            dispatch(canvasActrions.setActiveObject(null))
        } else {
            // SELECTED TEXT & EDIT VIEW
            //if (e.target.type === 'i-text' && e.target) {
            //    dispatch(canvasActrions.setActiveObject(e.target))
            //} else {
            //     
            //}
        }

        if (canvasFrame.getActiveObjects().length === 0) {
            let canvas = canvasFrame;

            let gg: any = {
                id: +localStorage.getItem('orderItemId'),
                body: {
                    'Canvas': JSON.stringify(canvas.toJSON()),
                    'Preview': canvas.toSVG(),
                }
            }


            dispatch(canvasActrions.setActiveObject(null))
            dispatch(orderActions.apiUpdateCanvasOrderItemById(gg))
        }
    }



    //useEffect(() => {
    //    if (selectedOrderItem.Id > 0) {
    //        if (selectedOrderItem.Canvas === null || selectedOrderItem.Preview === null) {
    //            let _productOption = new List<ProductOption>(selectedOrderItem.Product.ProductOptions).where(item => item.Option.TypeOption.IsColor === true).toArray();
    //            let _productOptions = new List<ProductOption>(newOrderItemProduct.ProductOptions).where(item => item.Option.TypeOption.IsColor === true).toArray();

    //            setTimeout(() => {
    //                setBackgroundImg((selectedProduct.Colors[0].Images && selectedProduct.Colors) ? selectedProduct.Colors[0].Images[0].ImageUrl : _productOption[0].Images[0].ImageUrl);
    //            }, 500)
    //        }
    //    }
    //}, [selectedOrderItem])

    //useEffect(() => {

    //    if (order.Id === 0 && selectedProduct.Id !== 0 && orderIdStorage !== 0) {

    //        dispatch(orderActions.apiGetOrderById(orderIdStorage))
    //    }
    //}, [orderIdStorage])

    return (
        <div className="constructor__CORE">
            <div className="left_frame_component">
                <div className="hideText" style={{ fontFamily: `${'Expel_Wide_Bold'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Colorado'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'DaVinci'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Denistina'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Disney'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Equestria'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Good_Vibes'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Graffiti_Shadow'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Holy_Mackerel'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Helvetica'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Karton'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Katherine'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Kobzar'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Metropolis'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Rosa_Marena'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Rupster_Script'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Sensei'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'SignPainter'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'SkipLegDay'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Spite_Graffiti'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'TonkyCaps'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'VellgishFont'}` }}>&nbsp;</div>
                <div className="hideText" style={{ fontFamily: `${'Xtreem'}` }}>&nbsp;</div>
            </div>
            <div className="header__COMPONENT">
                <HistoryView />
            </div>

            <OrderList />
            <MenuComponent />
            <div className="core__FRAME">
                <div className="frame__CONTAINER" id='workFrame__CONTAINER__ID'>
                    <div className="work__CONTAINER" id="work__CONTAINER__ID_SCREEN">
                        <canvas id="canvas__ID" />
                        {/*
                          <Route path={location.pathname}>
                        </Route >
                         */}
                    </div>
                </div>
            </div>
            <div className="wrapper__Drawer">
                <Drawer
                    placement="right"
                    visible={isOpenModal}
                    onClose={() => {
                        dispatch(controlActions.closeModal())
                        dispatch(canvasActrions.setActiveObject(null))
                    }}
                    getContainer={false}
                    destroyOnClose={true}
                    closable={true}
                    maskClosable={true}
                    width={680}
                    maskStyle={{ zIndex: 10, backgroundColor: 'rgba(255,255,255,0)' }}
                    style={{ position: 'absolute' }}
                >
                    {modalContent && modalContent.Component}
                </Drawer>
            </div>

            <div className="confirmOrder__MODAL">
                <Drawer
                    placement="right"
                    visible={isOpenConfirmModal}
                    onClose={() => {
                        dispatch(controlActions.saveCanvas(false))
                        dispatch(controlActions.closeModal())
                        dispatch(canvasActrions.setActiveObject(null))
                    }}
                    getContainer={false}
                    destroyOnClose={true}
                    closable={true}
                    maskClosable={true}
                    width={document.body.offsetWidth}
                    maskStyle={{ backgroundColor: 'rgba(255,255,255,0.8)' }}
                >
                    {modalConfirmContent && modalConfirmContent.Component}
                </Drawer>
            </div>

            {/*
             * (modalContent && modalContent.Size !== 'full') &&
             className={(modalContent && modalContent.Size === 'full') && 'drawer__fullSize'}
             */}
        </div>
    )
}