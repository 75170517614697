import React from 'react';
import { Switch, Redirect, Route } from 'react-router';
import * as routes from './../constans/routes.constants'
import { ProductsAll } from '../components/steps/products.all.step';
import { CategoriesAll } from '../components/steps/categories.all.step';
import { ConstructorCore } from '../components/application/constructor.core';
import { ConfirmedOrder } from '../components/steps/confirmed.order';

const MasterModule: React.FC = () => {
    return (
        <>
            <Switch>
                <Redirect exact={true}
                    from={routes.APP_URI}
                    to={routes.CATEGORIES_URI}
                />
                <Route exact={true} path={`${routes.PRODUCTS_URI}:categoryId`} component={ProductsAll} />
                <Route exact={true} path={`${routes.PRODUCT_URI}:productId`} component={ConstructorCore} />
                <Route path={`${routes.CATEGORIES_URI}`} component={CategoriesAll} />
                <Route path={`${routes.COMFIRMED_URI}`} component={ConfirmedOrder} />
            </Switch>
        </>
    )
}

export default MasterModule;