import { createSlice } from "@reduxjs/toolkit"
import { fabric } from "fabric";

export type HistoryTypeState = {
    textItem: fabric.IText,
    imgItem: fabric.IImageOptions,
    textList: fabric.IText[],
    imgList: fabric.IImageOptions[]
}

export const HistoryState: HistoryTypeState = {
    textItem: null,
    imgItem: null,
    textList: [],
    imgList:[]
}

const historySlice = createSlice({
    name: 'canvas',
    initialState: HistoryState,
    reducers: {
        setTextItem(state, action) {
            state.textItem = action.payload
        },
        setImgItem(state, action) {
            state.imgItem = action.payload
        },
        setTextList(state, action) {
            state.textList = action.payload
        },
        setImgListI(state, action) {
            state.imgList = action.payload
        },
    }
})

export const historyActrions = historySlice.actions;
export default historySlice.reducer;