import { Empty } from "antd";
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Category } from "../../../entities/category/category";
import { IApplicationState } from "../../../reducers/application.state";
import { categoryActrions } from "../../../reducers/category.slice";
import { controlActions } from "../../../reducers/control.slice";
import { NewOrderModalProductsList } from './order.new.products';

export const NewOrderCategoriesModal: React.FC = () => {
    const dispatch = useDispatch();

    const categories = useSelector<IApplicationState, Category[]>(state => state.category.categories);

    useEffect(() => {
        dispatch(categoryActrions.apiGetCategories())
    }, [])

    const openProductList = (_category: Category) => {
        let id: any = _category;
        dispatch(categoryActrions.apiGetCategoryByIdNewOrderModal(id.Id))
        dispatch(controlActions.setModalContent({ Component: <NewOrderModalProductsList />, Size: 'xxl' }))
    }

    return (
        <div className="categories__COMPONENT categories__STEP newOrderModal">
            <ul className="categories__wrapper">
                {categories.length > 0 ? categories.map((category: Category, i: number) => {
                    if (category.Categories.length > 0) {
                        return <li className="category_GroupItem" key={i}>
                            <div className="group__NAME">
                                <span>{category.Name}</span>
                            </div>

                            <ul>
                                {category.Categories.length > 0 ? category.Categories.map((sCategory: Category, x: number) =>
                                    <li className="s_category" key={x} onClick={() => openProductList(sCategory)}>
                                        <h3>{sCategory.Name}</h3>
                                    </li>
                                ) : null
                                }
                            </ul>
                        </li>
                    } else {
                        return null;
                    }
                }

                ) : <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'Категорий нет в наличии'} />}
            </ul>
        </div>
    )
}