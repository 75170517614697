import React, { useEffect } from "react"
import { IconNew, IconHistoryRemove } from "../../../constans/api.icons"
import { OrderItem } from "../../../models/order.item"
import { useSelector, useDispatch } from "react-redux"
import { IApplicationState } from "../../../reducers/application.state"
import { Order } from "../../../models/order"
import { Product } from "../../../entities/product/product"
import { orderActions } from "../../../reducers/order.slice"
import { controlActions } from "../../../reducers/control.slice"
import { canvasActrions } from "../../../reducers/canvas.slice"
import { fabric } from "fabric";
import { NewOrderCategoriesModal } from "./order.new"
import { List } from "linq-typescript"
import Item from "antd/lib/list/Item"
import { productActrions } from "../../../reducers/product.slice"

import * as routes from './../../../constans/routes.constants';
import { useHistory } from "react-router"

export const OrderList: React.FC = (props: any) => {
    const dispatch = useDispatch();
    const h = useHistory();
    const canvasFrame = useSelector<IApplicationState, fabric.Canvas>(state => state.canvas.canvas)

    const order = useSelector<IApplicationState, Order>(state => state.order.order)
    const orderItem = useSelector<IApplicationState, OrderItem>(state => state.order.selectedOrderItem)
    const selectedProduct = useSelector<IApplicationState, Product>(state => state.product.product)
    const orderIdStorage = useSelector<IApplicationState, number>(state => state.control.orderId)
    const orderItemIdStorage = useSelector<IApplicationState, number>(state => state.control.orderItemId)
    const createOrderItemState = useSelector<IApplicationState, boolean>(state => state.order.createOrderItem)

    useEffect(() => {
        if (createOrderItemState) {
            //let newCanva = canvasFrame;
            //newCanva._objects = []

            dispatch(canvasActrions.setCanvas(new fabric.Canvas('canvas__ID', {
                selection: false,
                controlsAboveOverlay: true,
                centeredScaling: true,
                allowTouchScrolling: true
            })))

            setTimeout(() => {
                createOrderItem()
            }, 1000);
        }

    }, [createOrderItemState])

    const createOrderItem = () => {
        
        /*dispatch(orderActions.createOrderItem(true))*/

        if (order.OrderItems.length < 10) {
            dispatch(controlActions.setModalContent({ Component: <NewOrderCategoriesModal />, Size: 'xxl' }))

            //canvasFrame.clear();
            //dispatch(orderActions.setOptionItem(optionItem))

            //dispatch(controlActions.setBackgroundImgUrl(selectedProduct.Colors[0].Images[0].ImageUrl))
            //dispatch(orderActions.setOptionItem(selectedProduct.Colors[0]))

            // Create New Order
            //dispatch(orderActions.apiCreateOrderItem(
            //    {
            //        "OrderId": orderIdStorage,
            //        "ProductId": selectedProduct.Id,
            //        "UsingImages": [],
            //        "OrderItemProductOptions": [
            //            {
            //                "ProductOption": {
            //                    "Id": selectedProduct.Colors[0].Id
            //                }
            //            }
            //        ],
            //        "Quantity": 1,
            //    }
            //))

            //dispatch(orderActions.createOrderItem(false))
        }
    }

    const selectOrderItem = (oItem: OrderItem) => {
        let storeOitemId = Number.parseInt(localStorage.getItem('orderItemId'));

        h.push(`${routes.PRODUCT_URI}${oItem.ProductId}`);
        
        if ((oItem.Id !== orderItem.Id) || (oItem.Id !== storeOitemId)) {
            canvasFrame.clear();
            //dispatch(orderActions.setOrderItemCount(oItem.Quantity))
            dispatch(controlActions.setStorageOrderItemId(oItem.Id))
            dispatch(orderActions.apiGetOrderItemById(oItem.Id))
            //dispatch(productActrions.apiGetProduct(oItem.Product.Id))
        }
    }

    const removeOrderItem = (orderItem: OrderItem, e) => {
        e.preventDefault();
        e.stopPropagation();
        //let _newOrder = new List<OrderItem>(order.OrderItems)
        //    .where(item => item.OrderId !== orderItem.OrderId).toArray();
        //dispatch(orderActions.setOrder({ ...order, OrderItems: _newOrder }))

        //let _productOption = new List<ProductOption>(productItem.ProductOptions).where(item => item.Option.TypeOption.IsColor === true).toArray();

        canvasFrame.clear();
        dispatch(orderActions.apiRemoveOrderItem(orderItem.Id))
    }

    return (
        <div className="add_newOrderItem__COMPONENT">
            <div className="new_layout__control" onClick={() => createOrderItem()}>
                <div className="iconNew">
                    <IconNew />
                </div>
                <div className="label__wrapper">
                    <div className="label">Добавить продукт</div>
                    <div className="description">создано {order && order.OrderItems ? order.OrderItems.length : null} с 10</div>
                </div>
            </div>

            <div className="all_layout__list">
                {
                    order.OrderItems ? order.OrderItems.map((orderItem: OrderItem, index: number) =>
                        <div className={`layout__list__item ${orderItem.Id === Number.parseInt(localStorage.getItem('orderItemId')) ? 'isSelected' : 'iNS'}`} key={index} onClick={() => { selectOrderItem(orderItem) }}>
                            <div className="item__wrapper">
                                <div className="layout__number">
                                    <span>макет </span>
                                    <span className="index">{index + 1}</span>
                                </div>
                                <div className="layout__name">{orderItem.Product.Name}</div>
                            </div>

                            {
                                order.OrderItems.length > 1 && orderItem.Id !== Number.parseInt(localStorage.getItem('orderItemId')) ?
                                    <div className="copy_open__control" onClick={(e) => { removeOrderItem(orderItem, e) }}>
                                        <IconHistoryRemove />
                                    </div> : null
                            }
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}