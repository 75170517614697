import { createSlice } from "@reduxjs/toolkit"
import { Category } from "../entities/category/category";

export type CategoryTypeState = {
    categories: Category[],
    subCategories: Category[],
    category: Category,
    selectCategoryNewOrderModal: Category
}

export const CategoryState: CategoryTypeState = {
    categories: [],
    subCategories: [],
    category: new Category(),
    selectCategoryNewOrderModal: new Category()
}

const categorySlice = createSlice({
    name: 'category',
    initialState: CategoryState,
    reducers: {
        apiGetCategories() { },
        apiGetCategoryById(state, action) { },
        apiGetCategoryByIdNewOrderModal(state, action) { },
        setCategories(state, action) { state.categories = action.payload },
        setSubCategories(state, action) { state.subCategories = action.payload },
        setCategory(state, action) { state.category = action.payload },
        setCategoryNewOrderModal(state, action) { state.selectCategoryNewOrderModal = action.payload }
    }
})

export const categoryActrions = categorySlice.actions;
export default categorySlice.reducer;