import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { switchMap, mergeMap, catchError } from "rxjs/operators";
import { IServiceApplicationState } from "../reducers/application.state";
import { categoryActrions } from "../reducers/category.slice";
import { API } from "../constans/api.constants";

export const GetAllCategories = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(categoryActrions.apiGetCategories.type),
        switchMap((action: any) => {
            return ajax
                .getJSON(`${API.CategoryIdentityEndPoints.GET__ALL_CATEGORIES}`, action.payload)
                .pipe(
                    mergeMap((response: any) => {
                        return of(categoryActrions.setCategories(response.Body))
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )

export const GetCategoryById = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(categoryActrions.apiGetCategoryById.type),
        switchMap((action: any) => {
             
            return ajax
                .getJSON(`${API.CategoryIdentityEndPoints.GET__CATEGORY_BY_ID}${action.payload}`, {})
                .pipe(
                    mergeMap((response: any) => {
                         
                        return of(categoryActrions.setSubCategories(response.Body))
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )

export const GetCategoryByIdNewOrderModal = (action$, state$: IServiceApplicationState) =>
    action$.pipe(
        ofType(categoryActrions.apiGetCategoryByIdNewOrderModal.type),
        switchMap((action: any) => {

            return ajax
                .getJSON(`${API.ProductIdentityEndPoints.GET__ALL_PRODUCTS}${action.payload}`, {})
                .pipe(
                    mergeMap((response: any) => {
                        
                        return of(categoryActrions.setCategoryNewOrderModal(response.Body))
                    }),
                    //catchError((error: any) => {
                    //    return ErrorHandler(error)
                    //})
                )
        }
        )
    )