import { createSlice } from "@reduxjs/toolkit"
import { Product } from "../entities/product/product";

export type ProductTypeState = {
    product: Product,
    selectedProductNewOrderItem: Product,
    products: Product[],
    productsIsList: Product[],
    changeProduct: Product
}

export const ProductState: ProductTypeState = {
    product: new Product(),
    selectedProductNewOrderItem: new Product(),
    products: [],
    productsIsList: [],
    changeProduct: new Product()
}

const productSlice = createSlice({
    name: 'product',
    initialState: ProductState,
    reducers: {
        apiGetProduct(state, action) { },
        apiGetChangeProduct(state, action) { },
        apiGetProductNewOrder(state, action) { },
        apiGetAllProducts(state, action) { },
        apiGetAllProductsList(state, action) { },
        setProduct(state, action) { state.product = action.payload },
        setChangeProduct(state, action) { state.changeProduct = action.payload },
        setProductNewOrderItem(state, action) { state.selectedProductNewOrderItem = action.payload },
        setProducts(state, action) { state.products = action.payload },
        setProductsList(state, action) { state.productsIsList = action.payload }
    }
})

export const productActrions = productSlice.actions;
export default productSlice.reducer;