import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { IApplicationState } from "../../reducers/application.state";
import { Product } from "../../entities/product/product";
import { productActrions } from "../../reducers/product.slice";
import { Empty } from "antd";
import { NavLink } from "react-router-dom";
import * as routes from './../../constans/routes.constants';

export const ProductsAll: React.FC = (props: any) => {
    const dispatch = useDispatch();
    const uriParams: { categoryId } = useParams()
    const categoryId: string = uriParams.categoryId;

    const products = useSelector<IApplicationState, Product[]>((state) => state.product.products)

    useEffect(() => {
        dispatch(productActrions.apiGetAllProducts(categoryId))
    }, [])

    return (
        <div className="products__COMPONENT products__STEP">
            <div className="products__wrapper">
                <div className="scroll__CONTENT">
                    {
                        products.length > 0 ? products.map((productItem: Product, index: number) =>
                            <div className="product_LIST__ITEM" key={index}>
                                <div className="item__CARD">
                                    <div className="item__BOX">
                                        <div className="box__IMG">
                                            <div className="image__WRAPPER">
                                                <div className="image__BOX">
                                                    {productItem.Image && <img src={productItem.Image.ImageUrl} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box__INFO">
                                            <div className="info__NAME">
                                                <span>Наименование</span>
                                                {productItem.Name}
                                            </div>
                                            <div className="info__PRICE">{productItem.Price} грн. / шт</div>
                                            <NavLink className="info__CREATE" to={`${routes.PRODUCT_URI}${productItem.Id}`}>
                                                Создать
                                            </NavLink>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ) : <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={'Продуктов нет в наличии'} />
                    }

                </div>

            </div>
        </div>
    )
}