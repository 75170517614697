import { createSlice } from '@reduxjs/toolkit'
import { Order } from '../models/order';
import { OrderItem } from '../models/order.item';
import { ProductOption } from '../models/productOption';

export type OrderTypeState = {
    order: Order,
    selectedOrderItem: OrderItem,
    orderItemCount: number,
    optionItem: ProductOption,
    createOrderItem: boolean,
    isOpenCreateModal: boolean
}

export const OrderState: OrderTypeState = {
    order: new Order(),
    selectedOrderItem: new OrderItem(),
    orderItemCount: 1,
    optionItem: null,
    createOrderItem: false,
    isOpenCreateModal:false
}

const orderSlice = createSlice({
    name: 'order',
    initialState: OrderState,
    reducers: {
        apiCreateOrder(state, action) { },
        apiConfirmOrder(state, action) { },
        apiGetOrderById(state, action) { },
        apiCreateOrderItem(state, action) { },
        apiGetOrderItemById(state, action) { },
        apiRemoveOrderItem(state, action) { },
        apiUpdateOrderItemById(state, action) { },
        apiUpdateCanvasOrderItemById(state, action) {},
        apiAddPreviewToOrderItemById(state, action) { },
        apiUpdateNotResponseOrderItemById(state, action) { },
        apiAddPictureToOrderItem(state, action) { },
        apiRemovePictureToOrderItem(state, action) { },
        setOrder(state, action) {
            state.order = action.payload
        },
        setOrderItem(state, action) {
             
            state.selectedOrderItem = action.payload
            
            if (state.order.OrderItems) {
                const indexOfCurrentOrderItem = state.order.OrderItems.findIndex((s) => s.Id === Number.parseInt(localStorage.getItem("orderItemId")))
                if (indexOfCurrentOrderItem >= 0){
                    let copyOfOrderItems = [...state.order.OrderItems]
                    copyOfOrderItems[indexOfCurrentOrderItem] = action.payload
                    state.order.OrderItems = copyOfOrderItems
                }
            } else {
                state.order = {
                    ...state.order,
                    OrderItems: []
                }
            }
            
        },
        setCanvasOrderItem(state, action) {
            let updatedOrderItem: OrderItem = action.payload;

            state.selectedOrderItem =
            {
                ...state.selectedOrderItem,
                Canvas: updatedOrderItem.Canvas,
                Preview: updatedOrderItem.Preview,
            }
        },
        setOrderItemCount(state, action) {
            state.orderItemCount = action.payload
        },
        setOptionItem(state, action) {
            state.optionItem = action.payload
        },
        createOrderItem(state, action) {
            state.createOrderItem = action.payload
        },
        setStateCreateModal(state, action) {
            state.isOpenCreateModal = action.payload
        }
    }
})

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;