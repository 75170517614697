import { createSlice } from "@reduxjs/toolkit"
import { ImageDefault } from "../models/image";

export type ControlTypeState = {
    isMenuOpen: boolean,
    orderId: number,
    orderItemId: number,
    modalContent: any,
    modalConfirmContent: any,
    isOpenModal: boolean,
    isOpenConfirmModal: boolean,
    backgroundImgUrl: string,
    isOpenColorPicker: boolean,
    imgToCanvas: ImageDefault,
    imgListCanvas: ImageDefault[],
    saveCanvas: boolean
}

export const ControlState: ControlTypeState = {
    isMenuOpen: false,
    orderId: 0,
    orderItemId: 0,
    modalContent: null,
    modalConfirmContent: null,
    isOpenModal: false,
    isOpenConfirmModal: false,
    backgroundImgUrl: '',
    isOpenColorPicker: false,
    imgToCanvas: null,
    imgListCanvas: [],
    saveCanvas: false
}

const controlSlice = createSlice({
    name: 'control',
    initialState: ControlState,
    reducers: {
        openMenu(state) {
            state.isMenuOpen = true
        },
        closeMenu(state) {
            state.isMenuOpen = false
        },
        closeModal(state) {
            state.isOpenModal = false
            state.isOpenConfirmModal = false
            state.modalContent = null
            state.modalConfirmContent = null
        },
        setModalContent(state, action) {
            state.modalContent = action.payload
            state.isOpenModal = true
        },
        setModalConfirmContent(state, action) {
            state.isOpenConfirmModal = true
            state.modalConfirmContent = action.payload
        },
        setStorageOrderId(state, action) {
            state.orderId = action.payload
            localStorage.setItem('orderId', action.payload)
        },
        setStorageOrderItemId(state, action) {
            if (action.payload > 0) {
                state.orderItemId = action.payload
                localStorage.setItem('orderItemId', action.payload)
            } else {
                
            }
        },
        getStorageOrderId(state) {
            state.orderId = +localStorage.getItem('orderId');
        },
        getStorageOrderItemId(state) {
            state.orderItemId = +localStorage.getItem('orderItemId');
        },
        setBackgroundImgUrl(state, action) {
            state.backgroundImgUrl = action.payload
        },
        setImgToCanvas(state, action) {
            state.imgToCanvas = action.payload
        },
        setImgListToCanvas(state, action) {
            state.imgListCanvas = action.payload
        },
        openColorPicker(state) {
            state.isOpenColorPicker = true
        },
        closeColorPicker(state) {
            state.isOpenColorPicker = false
        },
        saveCanvas(state, action) {
            state.saveCanvas = action.payload;
        },
        saveCanvasConfirmOrder(state, action) {
            state.saveCanvas = action.payload;
        },
        setOrderItemIdToStore(state, action) {
            localStorage.setItem('orderItemId', action.payload);
        }
    }
});

export default controlSlice.reducer;
export const controlActions = controlSlice.actions;