import { EntityBase } from "./entityBase";
import { OrderItem } from "./order.item";
import { OrderClient } from "./order.client";

export class Order extends EntityBase {
    public Number: string | undefined;
    public IsOrdered: boolean;
    public ClientId: number | undefined;
    public Client: OrderClient | undefined;
    public OrderItems: OrderItem[];
    public TotalPrice?: number;
}