import { EntityBase } from "./entityBase";
import { Product } from "./product";
import { Order } from "./order";
import { OrderItemProductOption } from "./order.item.product.option";
import { OrderItemText } from "./order.item.text";
import { ImageDefault } from "./image";

export class OrderItem extends EntityBase {
    public Canvas: any;
    public Quantity: number;
    public Price: number;
    public ProductId: number;
    public Product: Product;
    public OrderId: number;
    public Order: Order;
    public Preview: string;
    public OriginalImages: ImageDefault[];
    public UsingImages: ImageDefault[];
    public OrderItemTexts: OrderItemText[];
    public OrderItemProductOptions: OrderItemProductOption[];
    public PrintUnitPrice: number
    public ProductUnitPrice: number
    public TotalPrintPrice: number
    public TotalProductPrice: number
    public UnitPrice: number
}