import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { IApplicationState } from "../../../reducers/application.state";
import { Product } from "../../../entities/product/product";
import { Category } from "../../../entities/category/category";
import { categoryActrions } from "../../../reducers/category.slice";
import { Collapse, Select, Cascader } from "antd";
import { useParams, useLocation, Route } from "react-router";
import { productActrions } from "../../../reducers/product.slice";
import { orderActions } from "../../../reducers/order.slice";
import { OrderItem } from "../../../models/order.item";
import { controlActions } from "../../../reducers/control.slice";
import { canvasActrions } from "../../../reducers/canvas.slice";
import { ColorView } from "../color/color.view";
import { ProductOption } from "../../../models/productOption";
import { List } from "linq-typescript";

export const ProductsList: React.FC = (props: any) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const selectedProduct = useSelector<IApplicationState, Product>(state => state.product.product)
    const allCategories = useSelector<IApplicationState, Category[]>(state => state.category.categories)
    const allProductsByCategory = useSelector<IApplicationState, Product[]>(state => state.product.productsIsList)
    const category = useSelector<IApplicationState, Category>(state => state.category.category)
    const selectedOrderItem = useSelector<IApplicationState, OrderItem>(state => state.order.selectedOrderItem)
    const _changeProduct = useSelector<IApplicationState, Product>(state => state.product.changeProduct);

    const [_data, _setData] = useState<Product>(new Product());

    useEffect(() => {
        return () => {
            dispatch(productActrions.setChangeProduct(new Product()))
            dispatch(categoryActrions.setCategories([]));
        }
    }, [])

    useEffect(() => {
        if (allCategories.length === 0) {
            dispatch(categoryActrions.apiGetCategories())
        }

        if (category.Id === 0 && allCategories.length > 0) {
            dispatch(categoryActrions.setCategory(allCategories[0]))

        } else if (selectedProduct.Id !== 0 && allCategories.length > 0) {
            let ss = allCategories.filter(c => c.Id === selectedProduct.CategoryId)[0]

            //dispatch(categoryActrions.setCategory(allCategories[0]))
        }

    }, [category, allCategories])


    useEffect(() => {
        if (_changeProduct.Id > 0) {

            _setData(_changeProduct);
        }
    }, [_changeProduct])

    const changeProductInOrder = (selectColor: ProductOption) => {

        if (true) {
            //let printPlaceOption = new List<ProductOption>(_data.Colors)
            //    .where(pOption => pOption.Option.TypeOption.IsPrintPlace).toArray()[0];

            dispatch(orderActions.setOptionItem(selectColor))

            dispatch(productActrions.setProduct(_data))
            dispatch(orderActions.setOrderItem({ ...selectedOrderItem, Product: _data }))
            dispatch(controlActions.setBackgroundImgUrl(selectColor.Images[0].ImageUrl))

            dispatch(orderActions.apiUpdateOrderItemById(
                {
                    "Id": selectedOrderItem.Id,
                    "ProductId": _data.Id,
                    "Product": _data,
                    "OrderItemProductOptions": [
                        {
                            "ProductOption": {
                                "Id": selectColor.Id
                            }
                        }
                    ],
                    "Quantity": selectedOrderItem.Quantity,
                }
            ))
            _setData(new Product())
        }
    }
    
    const onChange = (value, label) => {
        if (label.length > 0) {
            dispatch(productActrions.apiGetAllProductsList(label[label.length - 1].Id))
        }
    };

    const options = [...allCategories]

    const change = (productOptionItem: any) => {
        changeProductInOrder(productOptionItem);

        dispatch(controlActions.closeModal())
        dispatch(canvasActrions.setActiveObject(null))
    }

    if (_changeProduct.Id > 0) {
        return (
            <div className="colorView__CONTAINER">
                <div className="">
                    <div className="productColors__ROW">

                        {_changeProduct && _changeProduct.Colors.map((optionItem: ProductOption, index: number) =>
                            <div className="color__ITEM" key={index} onClick={() => { change(optionItem) }}>
                                {optionItem.Images && <img src={optionItem.Images[0].ImageUrl} alt={''} />}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="product_LIST_VIEW">
                <div className={'modal__HEADER'}>
                    <Cascader
                        style={{ width: '100%' }}
                        fieldNames={{ label: 'Name', value: 'Id', children: 'Categories' }}
                        options={options.length > 0 ? options : []}
                        onChange={onChange}
                        placeholder="Выберите категорию"
                    />
                </div>

                <div className="scroll__CONTENT">
                    {
                        allProductsByCategory.length > 0 ? allProductsByCategory.map((productItem: Product, index: number) =>
                            <div className="product_LIST__ITEM" key={index}>
                                <div className="item__CARD">
                                    <div className="item__BOX">
                                        <div className="box__IMG">
                                            <div className="image__WRAPPER">
                                                <div className="image__BOX">
                                                    {productItem.Image && <img src={productItem.Image.ImageUrl} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box__INFO">
                                            <div className="info__NAME">
                                                <span>Наименование</span>
                                                {productItem.Name}
                                            </div>
                                            <div className="info__PRICE">{productItem.Price} грн. / шт</div>
                                            <div className="info__CREATE" onClick={() => dispatch(productActrions.apiGetChangeProduct(productItem.Id))}>Заменить</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ) : null
                    }

                </div>
            </div>
        )
    }


}