import { createSlice } from "@reduxjs/toolkit"
import { fabric } from "fabric";

export type CanvasTypeState = {
    canvas: fabric.Canvas,
    activeCanvasObject: any
}

export const CanvasState: CanvasTypeState = {
    canvas: null,
    activeCanvasObject:null
}

const canvasSlice = createSlice({
    name: 'canvas',
    initialState: CanvasState,
    reducers: {
        setCanvas(state, props) {
            console.log('setCanvas');
            state.canvas = props.payload
            state.canvas.stateful = false;
            state.canvas.renderAll.bind(state.canvas)
        },
        setActiveObject(state, action) {
            state.activeCanvasObject = action.payload
        },
        renderAll(state) {
            console.log('ss')
            state.canvas.stateful = false;
            state.canvas.renderAll.bind(state.canvas)
        },
        setBackgroundImg(state, props: any) {
            
        },
        setNewCanvas(state, action) {
            state.canvas = action.payload
        }
    }
})

export const canvasActrions = canvasSlice.actions;
export default canvasSlice.reducer;