export const fontFamilyList = [
    {
        FontName: 'Tahoma',
        FontTitle: 'Tahoma'
    },
    {
        FontName: 'Colorado',
        FontTitle: 'Colorado'
    },
    {
        FontName: 'DaVinci',
        FontTitle: 'DaVinci',
    },
    {
        FontName: 'Denistina',
        FontTitle: 'Denistina',
    },
    {
        FontName: 'Disney',
        FontTitle: 'Disney',
    },
    {
        FontName: 'Equestria',
        FontTitle: 'Equestria',
    },
    {
        FontName: 'Good_Vibes',
        FontTitle: 'Good_Vibes',
    },
    {
        FontName: 'Graffiti_Shadow',
        FontTitle: 'Graffiti_Shadow',
    },
    {
        FontName: 'Holy_Mackerel',
        FontTitle: 'Holy_Mackerel',
    },
    {
        FontName: 'Helvetica',
        FontTitle: 'Helvetica',
    },
    {
        FontName: 'Karton',
        FontTitle: 'Karton',
    },
    {
        FontName: 'Katherine',
        FontTitle: 'Katherine',
    },
    {
        FontName: 'Kobzar',
        FontTitle: 'Kobzar',
    },
    {
        FontName: 'Metropolis',
        FontTitle: 'Metropolis',
    },
    {
        FontName: 'Rosa_Marena',
        FontTitle: 'Rosa_Marena',
    },
    {
        FontName: 'Rupster_Script',
        FontTitle: 'Rupster_Script',
    },
    {
        FontName: 'Sensei',
        FontTitle: 'Sensei',
    },
    {
        FontName: 'SignPainter',
        FontTitle: 'SignPainter',
    },
    {
        FontName: 'SkipLegDay',
        FontTitle: 'SkipLegDay',
    },
    {
        FontName: 'Spite_Graffiti',
        FontTitle: 'Spite_Graffiti',
    },
    {
        FontName: 'TonkyCaps',
        FontTitle: 'TonkyCaps',
    },
    {
        FontName: 'VellgishFont',
        FontTitle: 'VellgishFont',
    },
    {
        FontName: 'Xtreem',
        FontTitle: 'Xtreem',
    }
];

export const fontFamilyListLat = [
    {
        FontName: 'Expel_Wide_Bold',
        FontTitle: 'Expel_Wide_Bold'
    },
    {
        FontName: 'Amita',
        FontTitle: 'Amita'
    },
    {
        FontName: 'Bad Script',
        FontTitle: 'Bad Script'
    },
    {
        FontName: 'Comfortaa',
        FontTitle: 'Comfortaa'
    },
    {
        FontName: 'Dancing Script',
        FontTitle: 'Dancing Script'
    },
    {
        FontName: 'Homemade Apple',
        FontTitle: 'Homemade Apple'
    },
    {
        FontName: 'Indie Flower',
        FontTitle: 'Indie Flower'
    },
    {
        FontName: 'Kaushan Script',
        FontTitle: 'Kaushan Script'
    },
    {
        FontName: 'Leckerli One',
        FontTitle: 'Leckerli One'
    },
    {
        FontName: 'Lobster',
        FontTitle: 'Lobster'
    },
    {
        FontName: 'Lobster Two',
        FontTitle: 'Lobster Two'
    },
    {
        FontName: 'Marck Script',
        FontTitle: 'Marck Script'
    },
    {
        FontName: 'Montserrat',
        FontTitle: 'Montserrat'
    },
    {
        FontName: 'Nothing You Could Do',
        FontTitle: 'Nothing You'
    },
    {
        FontName: 'Pacifico',
        FontTitle: 'Pacifico'
    },
    {
        FontName: 'Poiret One',
        FontTitle: 'Poiret One'
    },
    {
        FontName: 'Quicksand',
        FontTitle: 'Quicksand'
    },
];
