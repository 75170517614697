import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Product } from "../../../entities/product/product";
import { IApplicationState } from "../../../reducers/application.state";
import { IconHistoryText, IconHistoryImages, IconHistoryRemove } from "../../../constans/api.icons";
import { Popover, Avatar } from "antd";
import { fabric } from 'fabric';
import { canvasActrions } from "../../../reducers/canvas.slice";
import { orderActions } from "../../../reducers/order.slice";
import { OrderItem } from "../../../models/order.item";
import { ImageDefault } from "../../../models/image";
import { historyActrions } from "../../../reducers/history.slice";
import { DeleteOutlined } from '@ant-design/icons';

export const HistoryView: React.FC = () => {
    const dispatch = useDispatch();

    const canvasFrame = useSelector<IApplicationState, fabric.Canvas>(state => state.canvas.canvas)
    const historyTextList = useSelector<IApplicationState, fabric.IText[]>(state => state.history.textList)
    const historyImgList = useSelector<IApplicationState, fabric.IImageOptions[]>(state => state.history.imgList)
    const selectedOrderItemId = useSelector<IApplicationState, number>(state => state.control.orderItemId)
    const selectedOrderItem = useSelector<IApplicationState, OrderItem>(state => state.order.selectedOrderItem)

    const imgListToCanvas = useSelector<IApplicationState, ImageDefault[]>(state => state.control.imgListCanvas)

    const selectElement = (element: any) => {
        canvasFrame.setActiveObject(element);
        canvasFrame.requestRenderAll();
        if (canvasFrame._activeObject && element.type === 'i-text') {
            dispatch(canvasActrions.setActiveObject(element))
        }
    }

    const removeSelectElement = (element: any) => {
        if (element.type === 'i-text') {
            canvasFrame.setActiveObject(element);
            canvasFrame.remove(canvasFrame._activeObject);
            canvasFrame.requestRenderAll();
        } else if (element.type === 'image') {
            element.set({
                width: 0,
                height: 0
            })
            canvasFrame.remove(element);

            canvasFrame.requestRenderAll();
            dispatch(canvasActrions.renderAll())

            let picture = selectedOrderItem.UsingImages.filter(imgItem => imgItem.ImageUrl === element.src)[0]
            if (picture) {
                dispatch(orderActions.apiRemovePictureToOrderItem(picture.Id))
            }
        }

        if (selectedOrderItem.Id === selectedOrderItemId) {

            dispatch(orderActions.apiUpdateCanvasOrderItemById(
                {
                    id: selectedOrderItemId,
                    body: {
                        'Canvas': JSON.stringify(canvasFrame.toJSON()),
                        'Preview': canvasFrame.toSVG(),
                    }
                }
            ))
        }
    }

    const pictureList = (
        <div className="history__CONTENT imgs">
            {historyImgList.map((item: any, index: number) =>
                <div className="history__ITEM__IMG" key={index}>
                    <div className="click" onClick={() => selectElement(item)}>
                        <Avatar shape="square" size={110} src={(item.src && item) && item.src} />
                    </div>
                    <div className="controls">
                        <div className="removeItem" style={{ cursor: 'pointer' }}
                            onClick={() => removeSelectElement(item)}>
                            <DeleteOutlined />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )

    const textList = (
        <div className="history__CONTENT">
            {historyTextList.map((item: fabric.IText, index: number) =>
                <div className="history__ITEM__TEXT" key={index}>
                    <div className="item">
                        <div className="text" onClick={() => selectElement(item)}>
                            {item.text}
                        </div>
                        <div className="controls">
                            <div className="removeItem" style={{ cursor: 'pointer' }}
                                onClick={() => removeSelectElement(item)}>
                                <DeleteOutlined />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )

    return (
        <div className="history__COMPONENT">
            <Popover placement="bottomLeft" content={textList}
                title="Добавленые текста"
                trigger="click"
                destroyTooltipOnHide={true}>
                <div className={`history__ITEM text ${historyTextList.length > 0 && 'isSelected'}`}>
                    <IconHistoryText />
                    <div className="count">
                        {historyTextList.length}
                    </div>
                </div>
            </Popover>

            <Popover placement="bottomLeft" content={pictureList}
                title="Добавленые картинки"
                trigger="click"
                destroyTooltipOnHide={true}>
                <div className={`history__ITEM text ${historyImgList.length > 0 && 'isSelected'}`}>
                    <IconHistoryImages />
                    <div className="count">
                        {historyImgList.length}
                    </div>
                </div>
            </Popover>
        </div>
    )
}