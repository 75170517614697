import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IApplicationState } from "../../reducers/application.state";
import { categoryActrions } from "../../reducers/category.slice";
import { Category } from "../../entities/category/category";
import { NavLink } from "react-router-dom";
import * as routes from './../../constans/routes.constants';
import { Empty, Badge } from "antd";

export const CategoriesAll: React.FC = (props: any) => {
    const dispatch = useDispatch();

    const categories = useSelector<IApplicationState, Category[]>(state => state.category.categories);

    useEffect(() => {
        dispatch(categoryActrions.apiGetCategories())
    }, [])

    return (
        <div className="categories__COMPONENT categories__STEP">
            <ul className="categories__wrapper">
                {categories.length > 0 ? categories.map((category: Category, i: number) => {
                    if (category.Categories.length > 0) {
                        return <li className="category_GroupItem" key={i}>
                            <div className="group__NAME">
                                <span>{category.Name}</span>
                            </div>

                            <ul>
                                {category.Categories.length > 0 ? category.Categories.map((sCategory: Category, x: number) =>
                                    <li className="s_category" key={x}>
                                        <NavLink to={`${routes.PRODUCTS_URI}${sCategory.Id}`}>
                                            <h3>{sCategory.Name}</h3>
                                        </NavLink>
                                    </li>
                                ) : null
                                }
                            </ul>
                        </li>
                    } else {
                        return null;
                    }
                }
                    
                ) : <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={'Категорий нет в наличии'} />}
            </ul>
        </div>
    )
}