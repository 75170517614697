import { createSlice } from "@reduxjs/toolkit"
import { fabric } from "fabric";

export type ClientTypeState = {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string
}

export const ClientState: ClientTypeState = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
}

const clientSlice = createSlice({
    name: 'client',
    initialState: ClientState,
    reducers: {
        setClientName(state, action) {
            state.firstName = action.payload
            state.lastName = action.payload
        },
        setClientNumber(state, action) {
            state.phoneNumber = action.payload
        },
        setClientEmail(state, action) {
            state.email = action.payload
        },
    }
})

export const clientActrions = clientSlice.actions;
export default clientSlice.reducer;