import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import * as routes from './../../constans/routes.constants';

export const ConfirmedOrder: React.FC = () => {
    const dispatch = useDispatch();

    return (
        <div className="confirm_order__COMPONENT">
            <div className="statusOk_container">
                <div className="ok_title">Ваш заказ принят</div>
                <div className="okHelp_text">Мы свяжемся с вами в ближайшее время.</div>
                <div className="goBack_con">
                    <NavLink className="info__CREATE" to={`${routes.APP_URI}`}>
                        Вернуться на главную ?
                 </NavLink>
                </div>

                <div className="okVideo">
                    { /*
                      <iframe id="stopFullVideo"
                        src="https://www.youtube.com/embed/0tlR6TEbqUA?autoplay=1" allow="autoplay; fullscreen" frameBorder="0"></iframe>
                     */}
                </div>
            </div>


        </div>
    )
}