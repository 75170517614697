import { EntityBase } from "../entity.base";
import { Category } from "../category/category";
import { ProductOption } from "../../models/productOption";
import { ImageDefault } from "../../models/image";
import { PriceFrame } from "../../models/price.frame";
import { TypeOption } from "../../models/type.option";


export class Product extends EntityBase {
    public Name: string = '';
    public CategoryId: number = 0;
    public Category: Category = new Category();
    public ProductOptions: Array<ProductOption> = [];
    public Image: ImageDefault | undefined = undefined;
    public ImageId: number | undefined;
    public Colors: Array<ProductOption> = [];
    public Sizes: Array<ProductOption> = [];
    public PricePrint: number = 0;
    public Price: number = 0;
    public PriceFrames: Array<PriceFrame> = [];
    public TypeOptions: Array<TypeOption> = []
}