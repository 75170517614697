//export const API_SERVER = 'http://78.152.175.67:15010/';
export const API_SERVER = 'https://server.5print.ua/';

//AUTH
export const API_GET_AUTH = API_SERVER + "authentification/token/get?";
//
export const API_GET_ALL_CATEGORIES = API_SERVER + 'categories/all/get';
export const API_POST_CHANGE_CATEGORY = API_SERVER + 'categories/manage';
export const API_GET_CATEGORY_BY_ID = API_SERVER + 'categories/products/get?id=';
export const API_GET_PRODUCT_BY_ID = API_SERVER + 'products/get?id=';
export const API_POST_CHANGE_PRODUCT = API_SERVER + 'products/manage';
export const API_POST_CHANGE_OPTIONS = API_SERVER + 'options/manage';
export const API_GET_ALL_COLORS = API_SERVER + 'options/colors/get';
export const API_GET_CHANGE_COLORS = API_SERVER + 'options/color/manage';
export const API_POST_CHANGECOLOR_TO_PRODUCT = API_SERVER + 'products/manage/with/options';

                                    
// ORDERS
export const API_POST_NEW_ORDER = API_SERVER + 'orders/new';
export const API_GET_ORDER_BY_ID = API_SERVER + 'orders/get';
export const API_POST_UPDATE_ORDER = API_SERVER + 'orders/items/update';
export const API_POST_CREATE_ORDER_ITEM = API_SERVER + 'orders/items/new';
export const API_POST_UPDATE_USER_ORDER = API_SERVER + 'orders/change/status';
export const API_POST_ADD_IMG_TO_ORDER = API_SERVER + 'orders/items/images/add?id='
export const API_POST_ADD_PREVIEW_IMG_TO_ORDER = API_SERVER + 'orders/items/preview/add?id='

export class API {
    //public static readonly SERVER_URL = 'http://78.152.175.67:15010';
    public static readonly SERVER_URL = 'https://server.5print.ua';
    public static readonly API_PART = API.SERVER_URL + '/';
    
    public static readonly OrderIdentityEndPoints: any = {
        NEW__ORDER: API.API_PART + 'orders/new',
        GET__ORDER: API.API_PART + 'orders/get?id=',
        CONFIRM__ORDER: API.API_PART + 'orders/change/status',
        CREATE__ORDER_ITEM: API.API_PART + 'orders/items/new',
        GET__ORDER_ITEM: API.API_PART + 'orders/items/get?id=',
        ADD_PREVIEW__TO_ORDER_ITEM: API.API_PART + 'orders/items/preview/add?id=',
        ADD_PICTURE__TO_ORDER_ITEM: API.API_PART + 'orders/items/images/add?id=',
        REMOVE_PICTURE__TO_ORDER_ITEM: API.API_PART + 'orders/items/image/remove?id=',
        UPDATE__ORDER_ITEM: API.API_PART + 'orders/items/update',
        UPDATE_CANVAS__ORDER_ITEM: API.API_PART + 'orders/items/canvas/preview/update?id=',
        REMOVE__ORDER_ITEM: API.API_PART + 'orders/items/remove?id=',
    }

    public static readonly CategoryIdentityEndPoints: any = {
        GET__ALL_CATEGORIES: API.API_PART + 'categories/all/get',
        GET__CATEGORY_BY_ID: API.API_PART + 'categories/subcategories/get?id='
    }

    public static readonly ProductIdentityEndPoints = {
        GET__PRODUCT_BY_ID: API.API_PART + 'products/get?id=',
        GET__ALL_PRODUCTS: API.API_PART + 'categories/products/get?id='
    }
}