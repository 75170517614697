import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../reducers/application.state";
import { Order } from "../../models/order";
import { OrderItem } from "../../models/order.item";
import { orderActions } from "../../reducers/order.slice";
import { Button, Input } from "antd";
import { clientActrions } from "../../reducers/client.slice";
import { controlActions } from "../../reducers/control.slice";
import { IconHistoryRemove } from "../../constans/api.icons";
import { DeleteOutlined } from '@ant-design/icons';
import * as routes from './../../constans/routes.constants';
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';

export const ConfirmOrder: React.FC = () => {
    const dispatch = useDispatch();
    const order = useSelector<IApplicationState, Order>(state => state.order.order);

    console.log(order)

    const clientFName = useSelector<IApplicationState, string>(state => state.client.firstName)
    const clientLName = useSelector<IApplicationState, string>(state => state.client.lastName)
    const clientEmail = useSelector<IApplicationState, string>(state => state.client.email)
    const clientPhone = useSelector<IApplicationState, string>(state => state.client.phoneNumber)

    useEffect(() => {
        let id = localStorage.getItem('orderId');
        if (id) {
            dispatch(orderActions.apiGetOrderById(id))
        }
    }, [])

    const pushOrder = () => {
        dispatch(orderActions.apiConfirmOrder({
            "Id": order.Id,
            "Client": {
                FirstName: clientFName,
                LastName: clientLName,
                PhoneNumber: clientPhone,
                Email: clientEmail,
            }
        }))
        localStorage.clear();
        window.location.href = routes.COMFIRMED_URI;
        //push(`/app/product/${response.Body.Id}

        //dispatch(controlActions.closeModal())
    }

    const changeName = (e) => {
        dispatch(clientActrions.setClientName(e.target.value))
    }

    const changeEmail = (e) => {
        dispatch(clientActrions.setClientEmail(e.target.value))
    }

    const changePhone = (e) => {
        dispatch(clientActrions.setClientNumber(e.target.value))
    }

    const removeOrderItem = (orderItem: OrderItem) => {
        dispatch(orderActions.apiRemoveOrderItem(orderItem.Id))
    }

    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

    const confirmSchema = Yup.object().shape({
        email: Yup.string()
            .email(() => 'Email некорректный')
            .required(() => 'Email обязательный'),
        phone: Yup.string()
            .matches(phoneRegExp, 'Номер телефона некорректный')
            .required(() => 'Номер телефона обязательный'),
        userName: Yup.string()
            .required(() => 'ФИО обязательный'),
    })

    return (
        <div className="confirm_order__COMPONENT">
            <div className="confirm__HEADER">
                Готово, остался всего <b>один</b> шаг
                </div>
            <div className="orderItems__CONTAINER">
                <div className={`container__WRAPPER ${order && order.OrderItems.length === 1 ? "max_1" : ""} ${order && order.OrderItems.length === 2 ? "max_2" : ""}`}>
                    {
                        order && order.OrderItems.length > 0 ? order.OrderItems.map((oItem: OrderItem, index: number) =>
                            <div className="orderItem__COMPONENT" key={index}>
                                <div>
                                    <div className="imgPrew__CONTAINER">
                                        <div className="svgIMG__preview" dangerouslySetInnerHTML={{ __html: oItem.Preview }}></div>
                                        {
                                            order && order.OrderItems.length > 1 ?
                                                <div className="removeSelectOrderItem" onClick={() => removeOrderItem(oItem)}>
                                                    <DeleteOutlined />
                                                </div> : null
                                        }
                                    </div>

                                    <div className="orderItem__INFO">
                                        <div className="productName">Товар: {oItem.Product.Name}</div>
                                        <div className="productName">
                                            Тираж: {oItem.Quantity}<b>шт</b>
                                        </div>

                                        {oItem.OrderItemProductOptions.map((item: any, index: number) => {
                                            return <div className="productName" key={index}>
                                                {item.ProductOption.Option.TypeOption.Name === 'Color' ? 'Цвет: ' : `${item.ProductOption.Option.TypeOption.Name}: `}
                                                {item.ProductOption.Option.Name}
                                            </div>
                                        })}


                                        <div className="productName" style={{ marginTop: 20 }}>
                                            Цена за 1шт: {oItem.UnitPrice.toFixed(2)}<b> грн/шт</b>
                                        </div>
                                        <div className="productName">
                                            Цена за тираж: {(oItem.UnitPrice * oItem.Quantity).toFixed(2)}<b> грн</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
            <div className="user__COMPONENT">

                <div className="input__CONTROL_WRAPPER mBottom hide">
                    <div className="createOrder" >Загрузить оригиналы</div>
                </div>
                <br />
                <Formik
                    initialValues={{
                        userName: '',
                        email: '',
                        phone: ''
                    }}
                    validationSchema={confirmSchema}
                    onSubmit={(values) => {
                        const data: any = {
                            FirstName: values.userName,
                            LastName: values.userName,
                            PhoneNumber: values.phone,
                            Email: values.email,
                        }

                        dispatch(orderActions.apiConfirmOrder({
                            "Id": order.Id,
                            "Client": data
                        }))

                    }}
                >
                    {(formik) => (
                        <Form>
                            <div className="input__CONTROL_WRAPPER mBottom">
                                <div className="product__NAME_T">ФИО</div>
                                <Field
                                    name="userName"
                                    type="string"
                                    spellCheck="false"
                                    className="input__DEFAULT ant-input ant-input-lg"
                                    autoComplete="off"
                                />
                                {formik.errors.userName && formik.touched.userName ? (
                                    <span className="form_valMessage">
                                        {formik.errors.userName}
                                    </span>
                                ) : null}
                            </div>

                            <div className="input__CONTROL_WRAPPER mBottom">
                                <div className="product__NAME_T">Эл. почта</div>
                                <Field
                                    name="email"
                                    type="email"
                                    spellCheck="false"
                                    className="input__DEFAULT ant-input ant-input-lg"
                                    autoComplete="off"
                                />
                                {formik.errors.email && formik.touched.email ? (
                                    <span className="form_valMessage">
                                        {formik.errors.email}
                                    </span>
                                ) : null}
                            </div>

                            <div className="input__CONTROL_WRAPPER mBottom">
                                <div className="product__NAME_T">Телефон</div>
                                <Field
                                    name="phone"
                                    type="string"
                                    spellCheck="false"
                                    className="input__DEFAULT ant-input ant-input-lg"
                                    autoComplete="off"
                                />
                                {formik.errors.phone && formik.touched.phone ? (
                                    <span className="form_valMessage">
                                        {formik.errors.phone}
                                    </span>
                                ) : null}
                            </div>

                            <div className="input__CONTROL_WRAPPER mBottom">
                                <Button className="createOrder" htmlType="submit">Оформить заказ</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="deliveryPayment__col__row">
                    <div className="deliveryPayment__col__row__label">
                        Всего: <span className="dp__c"> {order.TotalPrice && order.TotalPrice.toFixed(2)} <span>грн</span></span>
                    </div>
                </div>

            </div>
        </div>
    )
}